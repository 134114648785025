import {
  ContractDefinition,
  ContractQueryRequest,
  ContractMessageRequest,
  createContractClient,
  snip721Def,
  extendContract,
  Snip721Contract,
} from "@stakeordie/griptape.js";

export interface Nft {
  approve(spender: string, token_id: string): Promise<{ approve: { status } }>;
  revoke(spender: string, token_id: string): Promise<{ revoke: { status } }>;
  getNftDossier(token_id: string, include_expired: boolean, isViewer: boolean): Promise<any>;
  getTokens(owner: string, viewingKey: string): Promise<any>;
  mintNft(tokenId: string, nft: any, fees: number): Promise<any>;
  createViewingKey(): Promise<any>;
}

const myDef: ContractDefinition = {
  queries: {
    getTokens(_ctx, owner, viewingKey): ContractQueryRequest {
      return {
        tokens: {
          owner,
          viewing_key: viewingKey,
        },
      };
    },

    getTokenApprovals(_ctx, token_id): ContractQueryRequest {
      const vk = _ctx.key;
      return {
        token_approvals: {
          token_id,
          viewing_key: vk,
          include_expired: true,
        },
      };
    },

    getNftDossier(_a, token_id, include_expired, isViewer): ContractQueryRequest {
      const { address } = _a;
      const vk = _a.key;
      const viewer = isViewer
        ? {
            address,
            viewing_key: vk,
          }
        : null;

      return {
        nft_dossier: {
          token_id,
          viewer,
          include_expired,
        },
      };
    },

    getPrivateMetadata(_a, token_id, isViewer): ContractQueryRequest {
      const { address } = _a;
      const vk = _a.key;
      const viewer = isViewer
        ? {
            address,
            viewing_key: vk,
          }
        : null;
      return {
        private_metadata: {
          token_id,
          viewer,
        },
      };
    },
  },

  messages: {
    mintNft(_ctx, tokenId, nft, fees): ContractMessageRequest {
      const handleMsg = {
        mint_nft: {
          token_id: tokenId,
          public_metadata: {
            extension: {
              image: null,
              image_data: null,
              external_url: null,
              description: nft.description,
              name: nft.name,
              mint_date: Math.round(Date.now() / 1000),
              background_color: null,
              animation_url: null,
              youtube_url: null,
              media: [
                {
                  file_type: "image",
                  extension: null,
                  authentication: {
                    key: "",
                    user: "",
                  },
                  url: nft.imageUrl,
                },
              ],
              protected_attributes: [],
            },
          },
        },
      };
      return { handleMsg, fees }; // 200000
    },

    approve(_ctx, spender, token_id, expires): ContractMessageRequest {
      const handleMsg = {
        approve: {
          spender,
          token_id,
          expires,
        },
      };
      return { handleMsg, fees: 200000 }; // 200000
    },

    revoke(_ctx, spender, token_id): ContractMessageRequest {
      const handleMsg = {
        revoke: {
          spender,
          token_id,
        },
      };
      return { handleMsg, fees: 200000 }; // 200000
    },
  },
};

export const extendedSnip721Def = extendContract(snip721Def, myDef);

export const NftContract = (nftCollectionAddress: string) => {
  const contract = createContractClient<Nft & Snip721Contract>({
    id: nftCollectionAddress,
    at: nftCollectionAddress,
    definition: extendedSnip721Def,
  });
  return contract;
};
