import * as React from "react";
import { Box, Typography, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { coinConvert } from "@stakeordie/griptape.js";

import { changeMinimumBid } from "infrastructure/store/slices/auction-details";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";

interface Props {
  auctionAddress: string;
  isOwner: boolean;
}

export const ChangeMinimumBid = (props: Props) => {
  const { auctionAddress, isOwner } = props;

  const state = useAppSelector((s) => s.auctionDetails);
  const dispatch = useAppDispatch();

  const [minimumBid, setMinimumBid] = React.useState<string>("");

  const handleChangeMinimumBid = (event) => {
    event.preventDefault();
    setMinimumBid(event.target.value);
  };

  const submitChangeMinimumBid = async () => {
    if (!minimumBid) return;
    const amount = coinConvert(minimumBid, 6, "machine");
    const fees = 250000;
    dispatch(changeMinimumBid({ auction_address: auctionAddress, amount, fees }));
  };

  if (!isOwner) return null;

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        Change Minimum Bid
      </Typography>

      <Box display="flex" gap={2}>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          value={minimumBid}
          onChange={handleChangeMinimumBid}
        />

        <LoadingButton
          sx={{ px: 4 }}
          loading={state.actionsLoading === "change-min-bid"}
          onClick={submitChangeMinimumBid}
          color="secondary"
          variant="outlined"
        >
          Change
        </LoadingButton>
      </Box>
    </>
  );
};
