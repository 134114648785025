import { NftContract } from "infrastructure/Contract/snip721";
import { getAddress, getClient } from "@stakeordie/griptape.js";
import { AUCTION_FACTORY_ADDRESS } from "app/utils/constants/addresses";

export class Collections {
  static createCollectionVk(collection_address: string) {
    const NftContractClient = NftContract(collection_address);
    return NftContractClient.createViewingKey();
  }

  static getCollectionCodeHash(collection_address: string) {
    return getClient().getCodeHashByContractAddr(collection_address);
  }

  static listCollectionTokens(collection_address: string, collection_vk: string) {
    const NftContractClient = NftContract(collection_address);
    const walletAaddress = getAddress();
    return NftContractClient.getTokens(walletAaddress, collection_vk);
  }

  static getNftDossier(collection_address: string, token_id: string) {
    const NftContractClient = NftContract(collection_address);
    return NftContractClient.getNftDossier(token_id, true, true);
  }

  static async approveNftAccess(collection_address: string, tokenId: string) {
    const NftContractClient = NftContract(collection_address);
    return NftContractClient.approve(AUCTION_FACTORY_ADDRESS, tokenId);
  }

  static async revokeNftAccess(collection_address: string, tokenId: string) {
    const NftContractClient = NftContract(collection_address);
    return NftContractClient.revoke(AUCTION_FACTORY_ADDRESS, tokenId);
  }

  static async mintNft(tokenId, nftAsset, fees) {
    const collectionAddress = "secret1ma6vkj8dffqlwu4cwddhcyhnm5ttqwukv6dy8r";
    const NftContractClient = NftContract(collectionAddress);
    return NftContractClient.mintNft(tokenId, nftAsset, fees);
  }

  static getContractInfo(collectionAddress: string) {
    const NftContractClient = NftContract(collectionAddress);
    return NftContractClient.getContractInfo();
  }
}
