export const profileHeader = {
  height: "250px",
  width: "100%",
  position: "relative",
  borderRadius: "12px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  "& .imageuploader2": {
    minWidth: "100%",
    zIndex: 10,
    height: "100%",
    border: "none",
    transition: "all 150ms linear",
    "& svg": { display: "none" },
    "& > div": { display: "none" },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
};
export const overlayContent = {
  position: "absolute",
  bottom: "16px",
  right: "16px",
  display: "flex",
  gap: 2,
  "& > .MuiBox-root": {
    zIndex: 9,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .MuiSvgIcon-root": {
      color: "white",
      width: "20px",
    },
  },
};

export const profileAvatarContainer = {
  marginTop: "-80px",
  display: "flex",
  paddingLeft: "30px",
  alignItems: "flex-end",

  gap: "16px",
};

export const profileAvatar = {
  zIndex: 12,
  padding: "8px",
  backgroundColor: "#FFF",
  borderRadius: "50%",
  position: "relative",
  "& .imageuploader1": {
    position: "absolute",
    zIndex: 15,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    height: "100%",
    minWidth: 0,
    borderRadius: "50%",
    border: "none",
    transition: "all 150ms linear",
    "& svg": { display: "none" },
    "& > div": { display: "none" },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
};

export const overlayProfile = {
  position: "absolute",
  top: "105%",
  left: "50%",
  display: "flex",
  transform: "translate(-50%, -50%)",
  "& > .MuiBox-root": {
    zIndex: 9,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    "& .MuiSvgIcon-root": {
      color: "white",
      width: "20px",
    },
  },
};
