import React from "react";
import "react-datetime/css/react-datetime.css";
import DatePicker from "react-datepicker";
import Box from "@mui/material/Box";
import "react-datepicker/dist/react-datepicker.css";
import useTheme from "@mui/material/styles/useTheme";

const getStyle = (theme) => {
  return {
    "& input": {
      height: "40px",
      border: "1px solid",
      borderColor: theme.palette.divider,
      borderRadius: "8px",
      padding: "0px 12px",
      fontSize: "14px",
      width: "100%",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    "& .react-datepicker": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,
    },
    "& .react-datepicker__header": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    "& .react-datepicker__day": {
      color: theme.palette.text.primary,
    },
    "& .react-datepicker__day-name": {
      color: theme.palette.text.primary,
    },
    "& .react-datepicker__current-month": {
      color: theme.palette.text.primary,
    },
  };
};

interface Props {
  label?: string;
  width?: string;
  selected?: any;
  rest?: any;
}

const DateTimePicker = (props: Props) => {
  const { label, width, selected, ...rest } = props;
  const theme = useTheme();

  return (
    <Box width={width} sx={getStyle(theme)}>
      <DatePicker
        selected={selected}
        {...rest}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
      />
    </Box>
  );
};

export default DateTimePicker;
