import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBackdrop-root": {
      marginTop: "80px",
    },
    "& .MuiDrawer-paper": {
      //  borderRadius: '8px 0px 0px 8px',
      boxShadow: "none",
      width: "75%",
      boxSizing: "border-box",
      marginTop: "80px",
      border: "none",
      background: theme.palette.background.paper,
    },

    [theme.breakpoints.down("md")]: {
      "& .MuiBackdrop-root": {
        marginTop: "80px",
      },
      "& .MuiDrawer-paper": {
        borderRadius: "0px",
        width: "100%",
        marginTop: "80px",
        paddingBottom: 100,
      },
      "& .MuiModal-root": {
        marginTop: "80px",
      },
    },
  },
  backBtn: { display: "none" },
  [theme.breakpoints.down("md")]: {
    backBtn: { display: "block" },
  },
}));
