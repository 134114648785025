export const addressValidator = (address: string) => {
  const match = address.match(/^secret[a-z0-9]/);
  const { length } = address.split("");
  if (!!match && length > 40 && length < 50) {
    return true;
  }
  return false;
};

export const extractAddressFromUrl = (url: string) => {
  if (!url) throw new Error("url not found!");

  const [address] = url.split("/").splice(-1);
  const isValidAddress = addressValidator(address);

  if (!isValidAddress) throw new Error("Address is not valid!");
  return address;
};

export const validateInt = (v) => {
  let error = false;
  const value = Number(v);
  if (v === "" || typeof value !== "number" || value < 0) {
    error = true;
  }
  return error;
};

export const validateText = (v) => {
  let error = false;

  if (v === "" || v.split().lenght < 40) {
    error = true;
  }

  return error;
};

export const nonIntersect = (arr1, arr2) => {
  const res = arr1.filter((unv) => {
    // eslint-disable-next-line
    for (let i = 0; i < arr2.length; i++) {
      if (unv.label === arr2[i].label) {
        return false;
      }
    }
    return true;
  });

  return res;
};

export const getPercentage = (num, percentage) => {
  return (num * percentage) / 100;
};
