import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import {
  getNativeBalance,
  getWrappedBalance,
  swapToken,
  createTokenVK,
  setTokenVk,
} from "./reducers";
import { initialState } from "./state";

const tokensSlice = createSlice({
  name: "tokens-slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTokenVK.pending, (state) => {
      state.loading.viewingKey = LOADING.PENDING;
    });

    builder.addCase(createTokenVK.fulfilled, (state, action) => {
      state.token.viewingKey = action.payload;
      state.loading.viewingKey = LOADING.SUCCEEDED;
    });

    builder.addCase(createTokenVK.rejected, (state, action) => {
      state.error = action.error;
      state.loading.viewingKey = LOADING.FAILED;
      // eslint-disable-next-line
      //@ts-ignore
      toast.error(action.error);
    });

    builder.addCase(setTokenVk.pending, (state) => {
      state.loading.viewingKey = LOADING.PENDING;
    });

    builder.addCase(setTokenVk.fulfilled, (state, action) => {
      state.token.viewingKey = action.payload;
      state.loading.viewingKey = LOADING.SUCCEEDED;
    });

    builder.addCase(setTokenVk.rejected, (state, action) => {
      state.error = action.error;
      state.loading.viewingKey = LOADING.FAILED;
      // eslint-disable-next-line
      //@ts-ignore
      toast.error(action.error);
    });

    builder.addCase(swapToken.pending, (state) => {
      state.loading.swap = LOADING.PENDING;
    });

    builder.addCase(swapToken.fulfilled, (state) => {
      state.loading.swap = LOADING.SUCCEEDED;
      toast.success("Tokens successfuly swapped !");
    });

    builder.addCase(swapToken.rejected, (state, action) => {
      state.error = action.error;
      state.loading.swap = LOADING.FAILED;
      // eslint-disable-next-line
      //@ts-ignore
      toast.error(action.error);
    });

    builder.addCase(getNativeBalance.pending, (state) => {
      state.loading.nativeBalance = LOADING.PENDING;
    });

    builder.addCase(getNativeBalance.fulfilled, (state, action) => {
      state.token.nativeBalance = action.payload;
      state.loading.nativeBalance = LOADING.SUCCEEDED;
    });

    builder.addCase(getNativeBalance.rejected, (state, action) => {
      state.error = action.error;
      state.loading.nativeBalance = LOADING.FAILED;
      // eslint-disable-next-line
      //@ts-ignore
      toast.error(action.error);
    });

    builder.addCase(getWrappedBalance.pending, (state) => {
      state.loading.wrappedBalance = LOADING.PENDING;
    });

    builder.addCase(getWrappedBalance.fulfilled, (state, action) => {
      state.token.wrappedBalance = action.payload;
      state.loading.wrappedBalance = LOADING.SUCCEEDED;
    });

    builder.addCase(getWrappedBalance.rejected, (state, action) => {
      state.error = action.error;
      state.loading.wrappedBalance = LOADING.FAILED;
      // eslint-disable-next-line
      //@ts-ignore
      toast.error(action.error);
    });
  },
});

export { getNativeBalance, getWrappedBalance, swapToken, createTokenVK, setTokenVk };

export default tokensSlice.reducer;
