import { LOADING } from "app/utils/constants/others";

interface Loading {
  getAuctionInfo: string;
  isAuctionOwner: string;
  isAuctionHasBids: string;
  placeBid: string;
  changeMinimumBid: string;
  retractBid: string;
  getPlacedBid: string;
  verifyAuction: string;
  saveFailedAuction: string;
  finalizeAuction: string;
}

const loading = {
  getAuctionInfo: LOADING.IDLE,
  isAuctionOwner: LOADING.IDLE,
  isAuctionHasBids: LOADING.IDLE,
  placeBid: LOADING.IDLE,
  changeMinimumBid: LOADING.IDLE,
  retractBid: LOADING.IDLE,
  getPlacedBid: LOADING.IDLE,
  verifyAuction: LOADING.IDLE,
  saveFailedAuction: LOADING.IDLE,
  finalizeAuction: LOADING.IDLE,
};

interface PlacedBid {
  status: string;
  amount_bid: string;
  message: string;
}
interface InitialStateProps {
  auction: any;
  isOwner: boolean;
  hasBids: boolean;
  loading: Loading;
  placedBid: PlacedBid;
  actionsLoading: string;
  errors: any | null;
}

export const initialState: InitialStateProps = {
  auction: null,
  isOwner: false,
  hasBids: false,
  placedBid: null,
  loading,
  actionsLoading: "",
  errors: null,
};
