import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { initialState } from "./state";
import { listLiveAuctions, listClosedAuctions, listUpcomingAuctions } from "./reducers";

const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    selectAuction: (state, action) => {
      state.selectedAuction = action.payload;
    },
    selectNextAuction: (state, action) => {
      const auctions = action?.payload;
      const index = auctions.findIndex((a) => a.address === state.selectedAuction.address);
      state.selectedAuction = auctions.length > index ? auctions[index + 1] : auctions[index];
    },
    selectPrevAuction: (state, action) => {
      const auctions = action?.payload;
      const index = auctions.findIndex((a) => a.address === state.selectedAuction.address);
      state.selectedAuction = index > 0 ? auctions[index - 1] : auctions[index];
    },

    searchAuctionByName: (state, action) => {
      const { key, type } = action.payload;
      const filtered = state.originalLists[type].filter((a) =>
        a.name.toLowerCase().includes(key.toLowerCase())
      );
      if (type === "live") {
        state.liveAuctions = filtered;
      }
      if (type === "closed") {
        state.closedAuctions = filtered;
      }
      if (type === "upcoming") {
        state.upcomingAuctions = filtered;
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(listLiveAuctions.pending, (state) => {
      state.loading.live = LOADING.PENDING;
    });
    builder.addCase(listLiveAuctions.fulfilled, (state, action) => {
      state.liveAuctions = action.payload;
      state.originalLists.live = action.payload;
      state.loading.live = LOADING.SUCCEEDED;
    });
    builder.addCase(listLiveAuctions.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.live = LOADING.FAILED;
    });

    builder.addCase(listClosedAuctions.pending, (state) => {
      state.loading.closed = LOADING.PENDING;
    });
    builder.addCase(listClosedAuctions.fulfilled, (state, action) => {
      state.closedAuctions = action.payload;
      state.originalLists.closed = action.payload;
      state.loading.closed = LOADING.SUCCEEDED;
    });
    builder.addCase(listClosedAuctions.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.closed = LOADING.FAILED;
    });

    builder.addCase(listUpcomingAuctions.pending, (state) => {
      state.loading.upcoming = LOADING.PENDING;
    });
    builder.addCase(listUpcomingAuctions.fulfilled, (state, action) => {
      state.upcomingAuctions = action.payload;
      state.originalLists.upcoming = action.payload;
      state.loading.upcoming = LOADING.SUCCEEDED;
    });
    builder.addCase(listUpcomingAuctions.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.upcoming = LOADING.FAILED;
    });
  },
});

export { listLiveAuctions, listClosedAuctions, listUpcomingAuctions };
export const { selectAuction, selectNextAuction, selectPrevAuction, searchAuctionByName } =
  marketplaceSlice.actions;
export default marketplaceSlice.reducer;
