import { configureStore } from "@reduxjs/toolkit";

import auctionDetailsReducer from "./slices/auction-details";
import authReducer from "./slices/authentication";
import dashboardReducer from "./slices/dashboard";
import userReducer from "./slices/user";
import createAuctionReducer from "./slices/auction-creation";
import marketplaceReducer from "./slices/marketplace";
import tokensReducer from "./slices/tokens";

const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    marketplace: marketplaceReducer,
    auctionDetails: auctionDetailsReducer,
    dashboard: dashboardReducer,
    createAuction: createAuctionReducer,
    tokens: tokensReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
