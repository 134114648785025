import React from "react";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const style = {
  lineHeight: 1.5,
  px: 1,
  fontWeight: "600",
  fontSize: "11px",
  borderRadius: "4px",
  display: "inline-block",
  textTransform: "upperCase",
};

export const Status = (props: { status: string; label: string; variant?: string }) => {
  const { status, label, variant = "normal" } = props;
  const [color, setColor] = React.useState({ text: "", bg: "" });

  React.useEffect(() => {
    switch (status) {
      case "success":
        setColor({ text: "#006644", bg: "#E3FCEF" });
        break;
      case "warning":
        setColor({ text: "#FF8B00 ", bg: "#FFFAE6" });
        break;
      case "error":
        setColor({ text: "#BF2600 ", bg: "#FFEBE6" });
        break;
      case "info":
        setColor({ text: "#0747A6", bg: "#DEEBFF" });
        break;
      case "accent":
        setColor({ text: "#403294 ", bg: "#EAE6FF" });
        break;

      default:
        setColor({ text: "#333 ", bg: "#CCC" });
        break;
    }
  }, [status, label]);

  if (variant === "small" && label) {
    return (
      <Tooltip title={label}>
        <Typography
          align="center"
          variant="body2"
          sx={{
            ...style,
            backgroundColor: color.bg,
            color: color.text,
          }}
        >
          {label === "Accepting Bids"
            ? "AB"
            : label === "Ready to Finalize"
            ? "RTF"
            : label === "Upcoming"
            ? "UPC"
            : label === "sold"
            ? "SOLD"
            : label === "closed"
            ? "CLOSED"
            : null}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <Typography
      align="center"
      variant="body2"
      sx={{
        ...style,
        backgroundColor: color.bg,
        color: color.text,
      }}
    >
      {label}
    </Typography>
  );
};
