import React from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import useSettings from "app/hooks/useSettings";

interface Props {
  amount: string | number;
}

export const LiveScrtPrice = (props: Props) => {
  const { amount } = props;
  const { scrtTokenInfo } = useSettings();

  if (!scrtTokenInfo?.current_price) return <CircularProgress size={15} />;

  const livePrice =
    scrtTokenInfo?.current_price && Number(scrtTokenInfo.current_price) * Number(amount);

  return (
    <Typography
      component="span"
      color="text.secondary"
      sx={{
        fontSize: "13px",
        pl: 1,
      }}
    >
      ≈ {Number(livePrice).toFixed(2)} $
    </Typography>
  );
};
