import * as React from "react";
import { LiveAuctionDetails } from "modules/marketplace/live-auctions/details";
import { Helmet } from "react-helmet";

const LiveAuctionsDetailsSlider = () => (
  <>
    <Helmet>
      <title>Marketplace | Details</title>
    </Helmet>
    <LiveAuctionDetails />
  </>
);

export default LiveAuctionsDetailsSlider;
