import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileService } from "infrastructure/services/server/profile";
import { CollectionsService } from "infrastructure/services/server/collections";
import { Collections } from "infrastructure/services/blockchain/collections";
import { getAddress, viewingKeyManager } from "@stakeordie/griptape.js";
import { Profile } from "app/types";
import { addressValidator } from "app/utils/_helpers";

interface CollectionAddress {
  collection_address: string;
}

export const getProfileInfo = async () => {
  let profile: any | "notFound";
  try {
    const { data } = await ProfileService.getProfile();
    profile = data;
  } catch (error) {
    if (error.request.status === 404) {
      profile = "notFound";
    } else {
      console.error(error);
    }
  }
  return profile;
};

const addProfile = async () => {
  const walletAddress = getAddress();
  const profile: Profile = {
    username: "",
    wallet_address: walletAddress,
    telegram_handle: null,
    twitter_handle: null,
    notification_active: false,
  };
  const {
    data: { data },
  } = await ProfileService.addProfile(profile);

  return { id: data.id, ...data.attributes };
};

export const getProfileAndAddIfNotFound = createAsyncThunk<unknown>("get-profile", async () => {
  let profile = await getProfileInfo();

  if (profile === "notFound") {
    profile = await addProfile();
  }
  return profile;
});

export const updateProfile = createAsyncThunk<
  any,
  { profile: any; id: string | number }
  // eslint-disable-next-line
>("update-profile", async ({ profile, id }, { rejectWithValue }) => {
  try {
    await ProfileService.updateProfile(profile, id);
    return profile;
  } catch (error) {
    throw new Error(error.response.data.error.name);
  }
});

export const uploadMedia = createAsyncThunk<
  { field: string; url: string },
  { img: File; profileId: string | number; field: string }
>("upload-media", async ({ img, profileId, field }) => {
  const res = await ProfileService.uploadProfileMedia(img, profileId, field);
  return {
    field,
    url: res.data[0].url,
  };
});

export const calculateUserEvaluation = async () => {
  try {
    await ProfileService.precalculateUserEvaluation();
    await ProfileService.calculateUserEvaluation();
  } catch (error) {
    console.error(error);
  }
};

export const getUserEvaluation = createAsyncThunk<unknown>("get-evaluation", async () => {
  await calculateUserEvaluation();
  const { data } = await ProfileService.getUserEvaluation();
  return data;
});

export const initUserAssets = createAsyncThunk("init-assets", async () => {
  try {
    await ProfileService.listUserCollections();
  } catch (error) {
    if (error?.response.status === 404) {
      ProfileService.initUserAssets();
    } else {
      throw error;
    }
  }
});

export const listUserCollections = createAsyncThunk("list-user-collections", async () => {
  const res = await ProfileService.listUserCollections();
  return res.data;
});

export const getContractInfo = async (address: string) => {
  // eslint-disable-next-line
  const { contract_info } = await Collections.getContractInfo(address);
  return contract_info;
};

interface UserCollection {
  userAssets: unknown;
  collection_address: string;
}

export const addUserCollection = createAsyncThunk<unknown, UserCollection>(
  "add-collection",
  async ({ userAssets, collection_address }) => {
    const collectionInfo = await getContractInfo(collection_address);
    if (!collectionInfo.name) throw new Error("Collection doesn't exist !");

    const collection = {
      collection_address,
      collection_name: collectionInfo.name,
    };

    const res = await ProfileService.addUserCollection(userAssets, collection);
    return res;
  }
);

export const listCollectionTokens = async (collection_address: string) => {
  if (!addressValidator(collection_address)) {
    throw new Error("invalid address given!");
  }

  const collectionVk = viewingKeyManager.get(collection_address);
  const {
    token_list: { tokens },
  } = await Collections.listCollectionTokens(collection_address, collectionVk);
  return tokens;
};

export const getCollectionNftsDossier = async (
  collection_address: string,
  tokensIds: Array<string>
) => {
  const promises = tokensIds.map((tokenId: string) => {
    return Collections.getNftDossier(collection_address, tokenId);
  });
  const result = await Promise.all(promises);

  return result;
};

export const importCollectionNfts = createAsyncThunk<unknown, CollectionAddress>(
  "get-nfts",
  async ({ collection_address }) => {
    const tokensIds = await listCollectionTokens(collection_address);
    const collectionNftsDossier = await getCollectionNftsDossier(collection_address, tokensIds);
    const collectionNfts = collectionNftsDossier.map(({ nft_dossier }, i) => {
      // eslint-disable-next-line
      const { public_metadata, token_approvals } = nft_dossier;
      const { extension } = public_metadata;
      return {
        id: tokensIds[i],
        name: extension?.name ?? "",
        description: extension?.description ?? "",
        image: extension?.media[0].url,
        approvals: token_approvals,
        collectionCreator: nft_dossier?.mint_run_info?.collection_creator,
        owner: nft_dossier?.owner,
      };
    });
    return { collection_address, collection_nfts: collectionNfts };
  }
);

export const getWhitelistingStatus = createAsyncThunk<boolean>("whitelisting-status", async () => {
  await CollectionsService.isWhitelisted();
  const { data } = await CollectionsService.isWhitelisted();
  return data;
});

export const mintNft = createAsyncThunk<unknown, { tokenId: string; nftAsset: any; fees: number }>(
  "mint",
  async ({ tokenId, nftAsset, fees }) => {
    await Collections.mintNft(tokenId, nftAsset, fees);
  }
);

export const incrementMint = createAsyncThunk<unknown, { userAssets: any }>(
  "increment-mint",
  async ({ userAssets }) => {
    await ProfileService.incrementMintAmount(userAssets);
  }
);
