import * as React from "react";
import { Stack, Toolbar, Box } from "@mui/material";
import { Link } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import NavLink from "components/NavLink";
import WalletButton from "components/WalletButton";
import blackLogo from "assets/logos/text-logo-black.png";
import whiteLogo from "assets/logos/text-logo-white.png";
import { ROUTES } from "app/utils/constants/routes";
import AddBoxIcon from "@mui/icons-material/AddBox";
import GridViewIcon from "@mui/icons-material/GridView";
import MapIcon from "@mui/icons-material/Map";

export const DesktopHeader = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <Toolbar
      sx={{
        height: "80px",
        display: { xs: "none", md: "flex" },
      }}
    >
      <Link to="/">
        <img
          loading="eager"
          src={mode === "light" ? blackLogo : whiteLogo}
          width={150}
          alt="logo"
        />
      </Link>

      <Stack flex={1} direction="row" pl={10} alignItems="flex-end" spacing={4}>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.MARKETPLACE_LIVE}
            label="Marketplace"
            icon={<GridViewIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.CREATE_AUCTION}
            label="Create Auction"
            icon={<AddBoxIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <NavLink
            to={ROUTES.ROADMAP}
            label="Roadmap"
            icon={<MapIcon style={{ fontSize: "16px", color: "#ccc" }} />}
          />
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ "& a": { textDecoration: "none" } }}
      >
        {/* <Button component={Link} to={ROUTES.MINT} variant="text" color="secondary" size="large">
          Mint
        </Button>

        <Divider orientation="vertical" flexItem /> */}
        <WalletButton />
      </Stack>
    </Toolbar>
  );
};
