import * as React from "react";
import { CircularProgress, Box } from "@mui/material";

export const SpinnerLoader = (props: { minHeight?: string }) => {
  const { minHeight = "100%" } = props;
  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight={minHeight}>
      <CircularProgress />
    </Box>
  );
};
