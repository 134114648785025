import * as React from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Loading } from "components/Loading";
import { LOADING } from "app/utils/constants/others";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { getAuctionInfo } from "infrastructure/store/slices/auction-details";
import { AuctionInfo } from "../components/AuctionInfo";
import { AuctionMetadata } from "../components/AuctionMetadata";
import { AuctionNFT } from "../components/AuctionNFT";

interface Props {
  auctionAddress: string;
}

export const Details = (props: Props) => {
  const { auctionAddress } = props;

  const state = useAppSelector((s) => s.auctionDetails);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAuctionInfo(auctionAddress));
  }, [auctionAddress, dispatch]);

  if (state.loading.getAuctionInfo === LOADING.PENDING) {
    return <Loading />;
  }

  const auction = state?.auction;

  return (
    <Box display={{ xs: "block", md: "flex" }} gap={4}>
      <Box flex={2}>
        <AuctionNFT nftUrl={auction?.image_url} />
      </Box>

      <Box flex={3}>
        <AuctionMetadata
          status={auction?.auction_status}
          name={auction?.name}
          endsAtTimestamp={auction?.ends_at_timestamp}
          description={auction?.description}
        />

        <AuctionInfo minimumBid={auction?.minimum_bid} startsAt={auction?.starts_at} />
      </Box>
    </Box>
  );
};
