import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { listMyAuctions, listUnverifiedAuctions } from "./reducers";

import { initialState } from "./state";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    listMyPendingAuctions: (state) => {
      state.pendingAuctions = state?.allAuctions?.unverified?.as_seller ?? [];
    },
    listMyLiveAuctions: (state) => {
      state.liveAuctions = state?.allAuctions?.active?.as_seller ?? [];
    },
    listMyClosedAuctions: (state) => {
      state.closedAuctions = state?.allAuctions?.closed?.as_seller ?? [];
    },
    listMyActiveBids: (state) => {
      state.activeBids = state?.allAuctions?.active?.as_bidder ?? [];
    },
    listMyWonBids: (state) => {
      state.wonBids = state?.allAuctions?.closed?.won ?? [];
    },
    listVerifiedByMeAuctions: (state) => {
      state.verifiedByMeAuctions = state?.allAuctions?.unverified?.as_verifier ?? [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listMyAuctions.pending, (state) => {
      state.loading.myAuctions = LOADING.PENDING;
    });
    builder.addCase(listMyAuctions.fulfilled, (state, action) => {
      state.allAuctions = action.payload;
      state.loading.myAuctions = LOADING.SUCCEEDED;
    });
    builder.addCase(listMyAuctions.rejected, (state, action) => {
      state.errors = action.error;
      state.loading.myAuctions = LOADING.FAILED;
    });

    builder.addCase(listUnverifiedAuctions.pending, (state) => {
      state.loading.unverified = LOADING.PENDING;
    });
    builder.addCase(listUnverifiedAuctions.fulfilled, (state, action) => {
      state.unverifiedAuctions = action.payload;
      state.loading.unverified = LOADING.SUCCEEDED;
    });
    builder.addCase(listUnverifiedAuctions.rejected, (state, action) => {
      state.errors = action.error;
      state.loading.unverified = LOADING.FAILED;
    });
  },
});

export { listMyAuctions, listUnverifiedAuctions };

export const {
  listMyPendingAuctions,
  listMyLiveAuctions,
  listMyClosedAuctions,
  listMyActiveBids,
  listMyWonBids,
  listVerifiedByMeAuctions,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
