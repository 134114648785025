import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Timer from "components/Timer";
import { AuctionStatus } from "app/types";
import { Status } from "components/Status";

interface Props {
  name: string;
  endsAtTimestamp?: number | undefined;
  description: string;
  hasBids?: boolean | undefined;
  status: AuctionStatus;
  isOwner?: boolean | undefined;
}

export const AuctionMetadata = (props: Props) => {
  const {
    name,
    endsAtTimestamp = undefined,
    description,
    hasBids = undefined,
    status,
    isOwner = undefined,
  } = props;

  return (
    <>
      <Box mb={1} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">{name}</Typography>

        {endsAtTimestamp ? <Timer endsAt={endsAtTimestamp} /> : null}
      </Box>
      <Typography variant="body2" color="textSecondary">
        {description}
      </Typography>

      <Divider textAlign="right">
        <Stack direction="row" spacing={1} my={1}>
          {status?.badges.map((badge) => (
            <Status key={badge.label} label={badge.label} status={badge.color} />
          ))}

          {hasBids && <Status label="Has Bids" status="success" />}
          {isOwner && <Status label="Owned by me" status="success" />}
        </Stack>
      </Divider>
    </>
  );
};
