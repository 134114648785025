import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const ImageContainer = styled(Box)(() => ({
  position: "relative",
  "&::after": {
    content: '""',
    display: "block",
    paddingBottom: "100%",
  },
  "& img": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
    // border: '1px solid #ccc'
  },
  "& .progress": {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
    // border: '1px solid #ccc'
  },
}));
