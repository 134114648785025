import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auctions } from "infrastructure/services/blockchain/auctions";
import { AuctionsService } from "infrastructure/services/server/auctions";
import { AuthService } from "infrastructure/services/blockchain/auth";
import { getNativeCoinBalance } from "@stakeordie/griptape.js";
import { AuctionVerification } from "app/types";

export const getAuctionInfo = createAsyncThunk("getinfo", async (auction_address: string) => {
  // eslint-disable-next-line
  const { auction_info } = await Auctions.getAuctionInfo(auction_address);
  return auction_info;
});

const checkOwnedAuctions = (auctions: any, target_auction: string) => {
  const activeOwned = auctions?.active?.as_seller?.filter(
    (auc: any) => auc.address === target_auction
  );
  const closedOwned = auctions?.closed?.as_seller?.filter(
    (auc: any) => auc.address === target_auction
  );

  const isOwner = !!(activeOwned.length > 0 || closedOwned.length > 0);
  return isOwner;
};

export const isAuctionOwner = createAsyncThunk("isOwner", async (auction_address: string) => {
  // eslint-disable-next-line
  const { list_my_auctions } = await Auctions.listMyAuctions();
  const isOwner = checkOwnedAuctions(list_my_auctions, auction_address);
  return isOwner;
});

export const isAuctionHasBids = createAsyncThunk("hasBids", async (auction_address: string) => {
  const res = await Auctions.hasBids(auction_address);
  return res;
});

export const getBalance = createAsyncThunk("get-balance", async () => {
  const res = await AuthService.getWrappedBalance();
  return res;
});

interface PlacedBid {
  wallet_address: string;
  auction_address: string;
  vk: string;
}
export const getPlacedBid = createAsyncThunk<any, PlacedBid>(
  "placedBid",
  async ({ wallet_address, auction_address, vk }) => {
    // eslint-disable-next-line
      //@ts-ignore
    const { bid } = await Auctions.getPlacedBid(wallet_address, auction_address, vk);
    return bid;
  }
);

interface PlaceBid {
  bid_contract_address: string;
  auction_address: string;
  amount: string;
}
export const placeBid = createAsyncThunk<void, PlaceBid>(
  "place-bid",
  async ({ bid_contract_address, auction_address, amount }) => {
    const balance = await getNativeCoinBalance();
    if (balance < amount) throw new Error("Insufficient balance !");
    await Auctions.placeBid(bid_contract_address, auction_address, amount);
  }
);

interface Finalize {
  auction_address: string;
  fees: number;
}
export const finalizeAuction = createAsyncThunk<void, Finalize>(
  "finalize",
  async ({ auction_address, fees }) => {
    await Auctions.finalize(auction_address, fees);
  }
);

interface ChangeMinBid {
  auction_address: string;
  amount: string;
  fees: number;
}
export const changeMinimumBid = createAsyncThunk<void, ChangeMinBid>(
  "change-min-bid",
  async ({ auction_address, amount, fees }) => {
    await Auctions.changeMinimumBid(auction_address, amount, fees);
  }
);

interface RetractBid {
  auction_address: string;
  fees: number;
}
export const retractBid = createAsyncThunk<void, RetractBid>(
  "retract-bid",
  async ({ auction_address, fees }) => {
    await Auctions.retractBid(auction_address, fees);
  }
);

interface Verify {
  auction_address: string;
  verification: AuctionVerification;
  fees: number;
}
export const verifyAuction = createAsyncThunk<void, Verify>(
  "verify-auction",
  async ({ auction_address, verification, fees }) => {
    await Auctions.verifyAuction(auction_address, verification, fees);
  }
);

export const saveFailedAuction = createAsyncThunk<boolean, string>(
  "save-failed-fuction",
  async (auction_address) => {
    // eslint-disable-next-line
    const { auction_info  } : any = await Auctions.getAuctionInfo(auction_address);
    if (auction_info.verification_status === "Failed") {
      await AuctionsService.saveFailedAuction(auction_address);
      return true;
    }
    return false;
  }
);
