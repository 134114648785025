import React from "react";
import { IconButton, Typography, Box, CircularProgress, Stack } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { LiveScrtPrice } from "components/LiveScrtPrice";
import { TextSmallPrimary } from "components/Typography";
import scrtIcon from "assets/icons/scrt.png";
import { useAppDispatch, useAppSelector } from "infrastructure/store/hooks";
import { LOADING } from "app/utils/constants/others";
import { setTokenVk, getWrappedBalance } from "infrastructure/store/slices/tokens";

const TokensList = () => {
  const {
    loading,
    token: { nativeBalance, wrappedBalance },
  } = useAppSelector((s) => s.tokens);

  const dispatch = useAppDispatch();

  const createViewingKey = async () => {
    await dispatch(setTokenVk());
    dispatch(getWrappedBalance());
  };

  return (
    <Box pt={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <img src={scrtIcon} width={25} alt="scrt" />
          <Stack>
            <TextSmallPrimary>SCRT</TextSmallPrimary>
            <Box display="flex" alignItems="center">
              <Typography color="primary" sx={{ fontSize: "13px" }}>
                {Number(nativeBalance).toFixed(2)}
              </Typography>
              <LiveScrtPrice amount={nativeBalance} />
            </Box>
          </Stack>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ py: 1 }}>
        <Box display="flex" alignItems="center" gap={2}>
          <img src={scrtIcon} width={25} alt="scrt" />
          <Stack>
            <TextSmallPrimary>sSCRT</TextSmallPrimary>
            {wrappedBalance === "" ? (
              <Box>
                {wrappedBalance === "" && loading.viewingKey !== LOADING.PENDING ? (
                  <IconButton
                    onClick={createViewingKey}
                    sx={{
                      p: 0,
                      "&.MuiButtonBase-root:hover": {
                        bgcolor: "transparent",
                      },
                    }}
                  >
                    <KeyIcon fontSize="small" color="primary" />
                  </IconButton>
                ) : loading.viewingKey === LOADING.PENDING ||
                  loading.wrappedBalance === LOADING.PENDING ? (
                  <CircularProgress size={20} />
                ) : null}
              </Box>
            ) : (
              <Box display="flex" alignItems="end">
                <Typography color="primary" sx={{ fontSize: "13px" }}>
                  {Number(wrappedBalance).toFixed(2)}
                </Typography>
                <LiveScrtPrice amount={wrappedBalance} />
              </Box>
            )}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default TokensList;
