import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Footer } from "./components/Footer";

export const FullScreenLayout = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <Box
      sx={{
        backgroundImage:
          mode === "dark"
            ? "linear-gradient(to left bottom, #0C1322, #161d2c, #2b2f36)"
            : "linear-gradient(to right top, #e4e4e4, #ebebeb, #fff)",
      }}
    >
      <Outlet />
      <Footer />
    </Box>
  );
};
