export const filterActiveAuctions = (auctions: any) => {
  let now = new Date().getTime() / 1000;
  now = Math.round(now);

  const upcoming = auctions.filter((auc) => auc.starts_at > now);
  const live = auctions.filter((auc) => auc.starts_at <= now);

  return {
    upcoming,
    live,
  };
};

export const verifyAuctionData = (minBid, duration, description) => {
  let error: any = {};
  const bid = Number(minBid);
  const dur = Number(duration);

  if (minBid === "" || typeof bid !== "number" || bid < 0) {
    error.minimumBid.msg = "enter a valid value";
  } else if (duration === "" || typeof dur !== "number" || dur < 0) {
    error.duration.msg = "enter a valid value";
  } else if (description === "" || description.split().lenght < 40) {
    error.duration.msg = "description is too short";
  } else {
    error = "no-errors";
  }

  return error;
};

export const getAuctionStatus = (startDate, endDate) => {
  let status;
  const d = new Date().getTime() / 1000;
  const now = Math.round(d);

  if (now >= endDate && now > startDate) {
    status = {
      label: "Ready to Finalize",
      isEnded: true,
      color: "error",
    };
  } else if (now < startDate) {
    status = {
      label: "Upcoming",
      isEnded: false,
      color: "info",
    };
  } else {
    status = {
      label: "Accepting Bids",
      isEnded: false,
      color: "success",
    };
  }

  return status;
};

export const getUpcomingAuctions = (auctions) => {
  const d = new Date().getTime() / 1000;
  const currentDate = Math.round(d);
  return auctions.filter((auc) => auc.starts_at > currentDate);
};
