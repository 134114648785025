import React from "react";
import { TextSmallSecondary, TextSmallPrimary } from "components/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

interface Props {
  auction: any;
}
export const NftMetadata = (props: Props) => {
  const { auction } = props;
  return (
    <Stack spacing={1} sx={{ my: 2 }}>
      <Box>
        <TextSmallSecondary>collection</TextSmallSecondary>
        <TextSmallPrimary>{auction?.collection_name}</TextSmallPrimary>
      </Box>
      <Box>
        <TextSmallSecondary>auction address</TextSmallSecondary>
        <TextSmallPrimary>{auction?.auction_address}</TextSmallPrimary>
      </Box>
    </Stack>
  );
};
