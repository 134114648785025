import * as React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { coinConvert, viewingKeyManager, getAddress } from "@stakeordie/griptape.js";
import LoadingButton from "@mui/lab/LoadingButton";
import { AUCTION_FACTORY_ADDRESS } from "app/utils/constants/addresses";
import { retractBid, getPlacedBid } from "infrastructure/store/slices/auction-details";
import { listMyAuctions } from "infrastructure/store/slices/dashboard";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";

interface Props {
  auctionAddress: string;
}

export const RetractBid = (props: Props) => {
  const { auctionAddress } = props;

  const state = useAppSelector((s) => s.auctionDetails);
  const dispatch = useAppDispatch();

  const submitRetractBid = async () => {
    const fees = 800000;
    await dispatch(retractBid({ auction_address: auctionAddress, fees }));
    dispatch(listMyAuctions());
  };

  React.useEffect(() => {
    const vk = viewingKeyManager.get(AUCTION_FACTORY_ADDRESS);
    const walletAddress = getAddress();
    dispatch(getPlacedBid({ wallet_address: walletAddress, auction_address: auctionAddress, vk }));
  }, [state.actionsLoading, auctionAddress, dispatch]);

  if (!state.placedBid) return null;
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        border: "1px solid  #b4eda0",
        p: 1,
        mt: 2,
        borderRadius: "8px",
      }}
    >
      <Box>
        <Stack direction="row" alignItems="center" gap={1} mb={1}>
          <Typography variant="body2" color="textSecondary">
            Placed Bid :{" "}
          </Typography>

          <Typography color="green">
            {coinConvert(state.placedBid.amount_bid, 6, "human")} sSCRT
          </Typography>
        </Stack>
        <Typography variant="body2" color="textSecondary">
          {state.placedBid.message}
        </Typography>
      </Box>

      <LoadingButton
        loading={state.actionsLoading === "retract-bid"}
        onClick={submitRetractBid}
        color="secondary"
        variant="outlined"
        sx={{
          height: "36px",
        }}
      >
        Retract
      </LoadingButton>
    </Stack>
  );
};
