import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import { RoutesLoader } from "components/RoutesLoader";

// layout
import { MarketplaceLayout } from "layouts/MarketplaceLayout";

// details pages
import LiveAuctionsDetailsSlider from "pages/marketplace/live-auctions-details";
import ClosedAuctionsDetailsSlider from "pages/marketplace/closed-auctions-details";
import UpcomingAuctionsDetailsSlider from "pages/marketplace/upcoming-auctions-details";

// pages
const LiveAuctionsPage = RoutesLoader(lazy(() => import("pages/marketplace/live-auctions")));
const ClosedAuctionsPage = RoutesLoader(lazy(() => import("pages/marketplace/closed-auctions")));
const UpcomingAuctionsPage = RoutesLoader(
  lazy(() => import("pages/marketplace/upcoming-auctions"))
);

const marketplaceRoutes: RouteObject = {
  path: "/marketplace",
  element: <MarketplaceLayout />,
  children: [
    {
      path: "",
      element: <Navigate to="live" replace />,
    },
    {
      path: "live",
      element: <LiveAuctionsPage />,
      children: [{ path: ":id", element: <LiveAuctionsDetailsSlider /> }],
    },
    {
      path: "closed",
      element: <ClosedAuctionsPage />,
      children: [{ path: ":id", element: <ClosedAuctionsDetailsSlider /> }],
    },
    {
      path: "upcoming",
      element: <UpcomingAuctionsPage />,
      children: [{ path: ":id", element: <UpcomingAuctionsDetailsSlider /> }],
    },
  ],
};

export default marketplaceRoutes;
