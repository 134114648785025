import { Auction } from "app/types";
import { LOADING } from "app/utils/constants/others";

interface Loading {
  importCollection: string;
  createCollectionVk: string;
  createAuction: string;
  approveNftAccess: string;
  revokeNftAccess: string;
  getCodeHash: string;
}
interface SelectedNFT {
  id: string;
  name: string;
  description: string;
  image: string;
  approvals: string[];
  collectionCreator: string;
  owner: string;
}
interface ImportedCollection {
  collection_address: string;
  collection_nfts: Array<any> | null;
}
interface InitialState {
  auction: Auction;
  importedCollection: ImportedCollection;
  selectedNft: SelectedNFT;
  isSelectedNftAccessApproved: boolean;
  isDisclaimerAccepted: boolean;
  loading: Loading;
  error: any;
}

const threeDaysLater = new Date().getTime() + 259200000;
export const initialAuction: Auction = {
  label: "",
  sell_contract: {
    code_hash: "",
    address: "",
  },
  sell_nft_token_id: "",
  bid_contract: {
    code_hash: "9587d60b8e6b078ace12014ceeee089530b9fabcd76535d93666a6c127ad8813",
    address: "secret18vd8fpwxzck93qlwghaj6arh4p7c5n8978vsyg",
  },
  minimum_bid: "",
  starts_at: threeDaysLater,
  duration: 12, // in hours - to be converted to seconds before submition
  description: "",
};

export const initialState: InitialState = {
  auction: initialAuction,
  importedCollection: { collection_address: "", collection_nfts: null },
  selectedNft: null,
  isSelectedNftAccessApproved: false,
  isDisclaimerAccepted: false,
  loading: {
    importCollection: LOADING.IDLE,
    createCollectionVk: LOADING.IDLE,
    createAuction: LOADING.IDLE,
    approveNftAccess: LOADING.IDLE,
    revokeNftAccess: LOADING.IDLE,
    getCodeHash: LOADING.IDLE,
  },
  error: null,
};
