export const saveItemToLS = (key: string, item: any): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(item));
  }
};
// eslint-disable-next-line
export const getItemFromLS = (key: string): any => {
  if (typeof window !== "undefined") {
    const item = window.localStorage.getItem(key);
    return JSON.parse(item);
  }
};

export const removeItemFromLS = (key: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
};
