import { AuctionFactoryContractClient } from "infrastructure/Contract/auction-factory";
import { BidContract } from "infrastructure/Contract/snip20";
import { coinConvert, getKeplr } from "@stakeordie/griptape.js";
import { SNIP20_ADDRESS } from "app/utils/constants/addresses";

export class AuthService {
  static tokenAddress() {
    return "secret18vd8fpwxzck93qlwghaj6arh4p7c5n8978vsyg";
  }

  static createAuthVK() {
    return AuctionFactoryContractClient.createViewingKey();
  }

  static getWrappedBalance() {
    const BidContractClient = BidContract(this.tokenAddress());
    return BidContractClient.getBalance();
  }

  static createTokenVK() {
    const BidContractClient = BidContract(this.tokenAddress());
    return BidContractClient.createViewingKey();
  }

  static async getSecret20Vk() {
    const chainId = "pulsar-02";
    const keplr = await getKeplr();
    return keplr.getSecret20ViewingKey(chainId, SNIP20_ADDRESS);
  }

  static setTokenVK(key: string) {
    const BidContractClient = BidContract(this.tokenAddress());
    return BidContractClient.setViewingKey(key);
  }

  static deposit(amount: string | number) {
    const theAmount = coinConvert(amount, 6, "machine");
    const BidContractClient = BidContract(this.tokenAddress());
    return BidContractClient.deposit(theAmount);
  }

  static redeem(amount: string | number) {
    const theAmount = coinConvert(amount, 6, "machine");
    const BidContractClient = BidContract(this.tokenAddress());
    return BidContractClient.redeem(theAmount);
  }
}
