import axios from "axios";
import { getAddress } from "@stakeordie/griptape.js";
import { getItemFromLS } from "app/utils/_helpers/localstorage";

interface AuthLS {
  address: string;
  jwt: string;
}

const getJwtToken = (): string => {
  const address = getAddress();
  const currentJwts: AuthLS[] = getItemFromLS("acti-jwt");

  const existing: AuthLS = currentJwts?.find((item: AuthLS) => item.address === address);

  return existing ? existing.jwt : "";
};

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}/api`,
});

API.interceptors.request.use((config) => {
  const token = getJwtToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

API.interceptors.request.use((config) => {
  const token = getJwtToken();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
