import { createSlice } from "@reduxjs/toolkit";
import { createCollectionVk } from "infrastructure/store/slices/auction-creation";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import {
  updateProfile,
  uploadMedia,
  getUserEvaluation,
  getProfileAndAddIfNotFound,
  initUserAssets,
  listUserCollections,
  addUserCollection,
  importCollectionNfts,
  getWhitelistingStatus,
  mintNft,
  incrementMint,
} from "./reducers";
import { initialState } from "./state";
import { updatedLoggedUserState } from "./helpers";

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileAndAddIfNotFound.pending, (state) => {
      state.loading.getProfile = LOADING.PENDING;
    });
    builder.addCase(getProfileAndAddIfNotFound.fulfilled, (state, action) => {
      const updatedState = updatedLoggedUserState(state, action.payload);
      return updatedState;
    });
    builder.addCase(getProfileAndAddIfNotFound.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getProfile = LOADING.FAILED;
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.loading.updateProfile = LOADING.PENDING;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      toast.success("Profile successfully updated.");
      return {
        ...state,
        loading: { ...state.loading, updateProfile: LOADING.SUCCEEDED },
        profileInfo: {
          ...state.profileInfo,
          ...action.payload,
        },
      };
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.error = action.error;
      state.loading.updateProfile = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(uploadMedia.pending, (state) => {
      state.loading.uploadMedia = LOADING.PENDING;
    });
    builder.addCase(uploadMedia.fulfilled, (state, action) => {
      const baseUrl = process.env.REACT_APP_API_HOST;
      state.profileMedia[action.payload.field] = baseUrl + action.payload.url;
      state.loading.uploadMedia = LOADING.SUCCEEDED;
      toast.success("Image successfully uploaded.");
    });
    builder.addCase(uploadMedia.rejected, (state, action) => {
      state.error = action.error;
      state.loading.uploadMedia = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(getUserEvaluation.pending, (state) => {
      state.loading.getEvaluation = LOADING.PENDING;
    });
    builder.addCase(getUserEvaluation.fulfilled, (state, action) => {
      state.userEvaluation = action.payload;
      state.loading.getEvaluation = LOADING.SUCCEEDED;
    });
    builder.addCase(getUserEvaluation.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getEvaluation = LOADING.FAILED;
    });

    builder.addCase(initUserAssets.pending, (state) => {
      state.loading.initAssets = LOADING.PENDING;
    });
    builder.addCase(initUserAssets.fulfilled, (state) => {
      state.loading.initAssets = LOADING.SUCCEEDED;
    });
    builder.addCase(initUserAssets.rejected, (state) => {
      state.loading.initAssets = LOADING.FAILED;
    });

    builder.addCase(listUserCollections.pending, (state) => {
      state.loading.getAssets = LOADING.PENDING;
    });
    builder.addCase(listUserCollections.fulfilled, (state, action) => {
      state.userAssets = { ...state.userAssets, ...action.payload };
      state.loading.getAssets = LOADING.SUCCEEDED;
    });
    builder.addCase(listUserCollections.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getAssets = LOADING.FAILED;
    });

    builder.addCase(addUserCollection.pending, (state) => {
      state.loading.addCollection = LOADING.PENDING;
    });
    builder.addCase(addUserCollection.fulfilled, (state) => {
      state.loading.addCollection = LOADING.SUCCEEDED;
      toast.success("Collection successfully added.");
    });
    builder.addCase(addUserCollection.rejected, (state, action) => {
      state.error = action.error;
      state.loading.addCollection = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(createCollectionVk.pending, (state) => {
      state.loading.createCollectionVk = LOADING.PENDING;
    });
    builder.addCase(createCollectionVk.fulfilled, (state) => {
      state.loading.createCollectionVk = LOADING.SUCCEEDED;
    });
    builder.addCase(createCollectionVk.rejected, (state, action) => {
      state.error = action.error;
      state.loading.createCollectionVk = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(importCollectionNfts.pending, (state) => {
      state.loading.importCollectionNfts = LOADING.PENDING;
    });
    builder.addCase(importCollectionNfts.fulfilled, (state, action) => {
      state.userAssets.importedCollection = action.payload;
      // eslint-disable-next-line
      if (state.userAssets?.hasOwnProperty("nfts")) {
        state.userAssets.nfts = [...state.userAssets.nfts, action.payload];
      } else {
        state.userAssets.nfts = [action.payload];
      }
      state.loading.importCollectionNfts = LOADING.SUCCEEDED;
      toast.success("Nfts successfully imported");
    });
    builder.addCase(importCollectionNfts.rejected, (state, action) => {
      state.error = action.error;
      state.loading.importCollectionNfts = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(getWhitelistingStatus.pending, (state) => {
      state.loading.whitelisting = LOADING.PENDING;
    });
    builder.addCase(getWhitelistingStatus.fulfilled, (state, action) => {
      state.isWhitelisted = action.payload;
      state.loading.whitelisting = LOADING.SUCCEEDED;
    });
    builder.addCase(getWhitelistingStatus.rejected, (state, action) => {
      state.loading.whitelisting = LOADING.FAILED;
      state.error = action.error;
    });

    builder.addCase(mintNft.pending, (state) => {
      state.loading.mint = LOADING.PENDING;
    });
    builder.addCase(mintNft.fulfilled, (state) => {
      state.loading.mint = LOADING.SUCCEEDED;
      toast.success("Secret NFT has been minted! Check your collections under profile/assets.");
    });
    builder.addCase(mintNft.rejected, (state, action) => {
      state.error = action.error;
      state.loading.mint = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(incrementMint.rejected, (state, action) => {
      state.error = action.error;
      state.loading.mint = LOADING.FAILED;
      toast.error(action.error.message);
    });
  },
});

export {
  updateProfile,
  uploadMedia,
  getUserEvaluation,
  getProfileAndAddIfNotFound,
  initUserAssets,
  listUserCollections,
  addUserCollection,
  createCollectionVk,
  importCollectionNfts,
  getWhitelistingStatus,
  mintNft,
  incrementMint,
};

export default userSlice.reducer;
