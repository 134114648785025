import { LOADING } from "app/utils/constants/others";

interface Loading {
  live: string;
  closed: string;
  upcoming: string;
}

interface InitialStateProps {
  liveAuctions: Array<any>;
  closedAuctions: Array<any>;
  upcomingAuctions: Array<any>;

  // used to save original data when filtring
  originalLists: {
    live: Array<any>;
    closed: Array<any>;
    upcoming: Array<any>;
  };
  selectedAuction: any;
  loading: Loading;
  errors: any | null;
}

export const initialState: InitialStateProps = {
  liveAuctions: [],
  closedAuctions: [],
  upcomingAuctions: [],
  originalLists: {
    live: [],
    closed: [],
    upcoming: [],
  },
  selectedAuction: null,
  loading: {
    live: LOADING.IDLE,
    closed: LOADING.IDLE,
    upcoming: LOADING.IDLE,
  },
  errors: null,
};
