import {
  ContractDefinition,
  ContractQueryRequest,
  ContractMessageRequest,
  createContractClient,
} from "@stakeordie/griptape.js";
import { AuctionVerification } from "app/types";

interface Auction {
  // eslint-disable-next-line
  getAuctionInfo(): Promise<{ auction_info: {} }>;
  hasBids(
    address: string,
    vk: string
  ): Promise<{ has_bids: { address: string; viewing_key: string } }>;
  getPlacedBid(
    address: string,
    vk: string
  ): Promise<{ view_bid: { address: string; viewing_key: string } }>;
  verify(verification: AuctionVerification, fees: number): Promise<any>;
  changeMinimumBid(amount: string, fees: number): Promise<any>;
  finalize(fees: number): Promise<any>;
  retractBid(fees: number): Promise<any>;
}

const auctionDef: ContractDefinition = {
  queries: {
    getAuctionInfo(): ContractQueryRequest {
      return { auction_info: {} };
    },

    hasBids(_ctx, address: string, viewing_key: string): ContractQueryRequest {
      return { has_bids: { address, viewing_key } };
    },

    getPlacedBid(_ctx, address: string, viewing_key: string): ContractQueryRequest {
      return { view_bid: { address, viewing_key } };
    },
  },

  messages: {
    verify(_ctx, verification: AuctionVerification, fees): ContractMessageRequest {
      const handleMsg = { verify: verification };
      return { handleMsg, fees };
    },

    changeMinimumBid(_ctx, amount: string, fees: number): ContractMessageRequest {
      const handleMsg = {
        change_minimum_bid: {
          minimum_bid: amount,
        },
      };
      return { handleMsg, fees }; // 250000
    },
    finalize(_ctx, fees): ContractMessageRequest {
      const handleMsg = {
        finalize: {},
      };
      return { handleMsg, fees }; // 400000
    },

    retractBid(_ctx, fees): ContractMessageRequest {
      const handleMsg = {
        retract_bid: {},
      };
      return { handleMsg, fees }; // 400000
    },
  },
};

export const AuctionContract = (address: string) => {
  const contract = createContractClient<Auction>({
    id: address,
    at: address,
    definition: auctionDef,
  });
  return contract;
};
