const components = {
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.1) 0px 10px 50px" : "none",
      }),
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        boxShadow: theme.palette.mode === "light" ? "rgba(0, 0, 0, 0.1) 0px 10px 50px" : "none",
      }),
    },
  },

  MuiAppBar: {
    styleOverrides: {
      root: {
        zIndex: 10000,
        boxShadow: "none",
        borderRadius: 0,
      },
      colorInherit: {
        backgroundColor: "#fff",
        zIndex: 9000,
      },
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 14,
      },
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: {
        fontSize: 13,
        borderRadius: "6px",
      },
    },
  },
  MuiPopover: {
    styleOverrides: {
      paper: {
        boxShadow: "none",
        border: "1px solid #ccc",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      disableElevation: true,
      size: "large",
    },
    styleOverrides: {
      root: () => ({
        textTransform: "none",
        fontSize: 14,
        fontWeight: 500,
        borderRadius: "6px",
        paddingLeft: 24,
        paddingRight: 24,
        /*  transition: 'all 250ms linear',
                opacity: 0.9,
                '&.MuiButton-containedPrimary:hover': {
                    opacity: 1
                } */
      }),

      containedPrimary: {
        color: "#fff",
        /* background:
                    'linear-gradient(to right, #f83600 0%, #f9d423 100%)' */
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      defaultProps: {
        size: "small",
      },
      root: {
        fontWeight: 600,
        fontSize: "12px!important",
        padding: "0px 4px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        height: "40px!important",
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        borderRadius: "4px",
      },
    },
  },
};

export default components;
