import { createAsyncThunk } from "@reduxjs/toolkit";
import { ProfileService } from "infrastructure/services/server/profile";
import { AuthService } from "infrastructure/services/blockchain/auth";
import { bootstrap, getWalletInfo, viewingKeyManager } from "@stakeordie/griptape.js";
import { AUCTION_FACTORY_ADDRESS } from "app/utils/constants/addresses";
import { AuctionFactoryContractClient } from "infrastructure/Contract/auction-factory";
import { addressValidator } from "app/utils/_helpers";
import { setAuthLS, getExistingAuthLS } from "./helpers";

export const createAuthViewingKey = async () => {
  const res = await AuthService.createAuthVK();
  if (res.isEmpty()) throw new Error("can't create auth viewing key !");

  const {
    viewing_key: { key },
  } = res.parse();
  // eslint-disable-next-line
  // @ts-ignore
  viewingKeyManager.add(AuctionFactoryContractClient, key);

  return key;
};

export const authenticate = async (vk: string) => {
  const {
    data: { jwt },
  } = await ProfileService.authenticate(vk);
  setAuthLS(jwt);

  return jwt;
};

export const connectWallet = createAsyncThunk("connect", async () => {
  await bootstrap();
  const { name, address } = getWalletInfo();
  if (!addressValidator(address)) throw new Error("Attemp to connect unsupported wallet !");
  return {
    walletName: name,
    walletAddress: address,
  };
});

export const currentAuthenticatedUser = createAsyncThunk("current-auth-user", async () => {
  const jwt = getExistingAuthLS();
  if (!jwt) throw new Error("You are logged out !");
  const authVk = viewingKeyManager.get(AUCTION_FACTORY_ADDRESS);

  return { authVk, jwt };
});

export const login = createAsyncThunk("login", async () => {
  const authVk = await createAuthViewingKey();
  const jwt = await authenticate(authVk);

  return { authVk, jwt };
});
