import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import { RoutesLoader } from "components/RoutesLoader";
import { ROUTES } from "app/utils/constants/routes";
import { DashboardLayout } from "layouts/DashbordLayout";

const DashboardAuctionsPage = RoutesLoader(lazy(() => import("pages/dashboard/auctions")));
const PendingAuctionsPage = RoutesLoader(
  lazy(() => import("pages/dashboard/auctions/pending-auctions"))
);
const LiveAuctionsPage = RoutesLoader(lazy(() => import("pages/dashboard/auctions/live-auctions")));
const ClosedAuctionsPage = RoutesLoader(
  lazy(() => import("pages/dashboard/auctions/closed-auctions"))
);
const DashboardClosedAuctionDetails = RoutesLoader(
  lazy(() => import("pages/dashboard/auctions/closed-auctions-details"))
);
const DashboardLiveAuctionDetails = RoutesLoader(
  lazy(() => import("pages/dashboard/auctions/live-auctions-details"))
);
const DashboardPendingAuctionDetails = RoutesLoader(
  lazy(() => import("pages/dashboard/auctions/pending-auctions-details"))
);

const DashboardBidsPage = RoutesLoader(lazy(() => import("pages/dashboard/bids")));
const WonBidsPage = RoutesLoader(lazy(() => import("pages/dashboard/bids/won-bids")));
const ActiveBidsPage = RoutesLoader(lazy(() => import("pages/dashboard/bids/active-bids")));
const DashboardActiveBidsDetails = RoutesLoader(
  lazy(() => import("pages/dashboard/bids/active-bids-details"))
);
const DashboardWonBidsDetails = RoutesLoader(
  lazy(() => import("pages/dashboard/bids/won-bids-details"))
);

const DashboardVerificationPage = RoutesLoader(lazy(() => import("pages/dashboard/verification")));
const UnverifiedAuctionsPage = RoutesLoader(
  lazy(() => import("pages/dashboard/verification/unverified-auctions"))
);
const VerifiedByMeAuctionsPage = RoutesLoader(
  lazy(() => import("pages/dashboard/verification/verified-auctions"))
);
const VerifyAuctionSlider = RoutesLoader(
  lazy(() => import("pages/dashboard/verification/verify-auction"))
);

const dashboardRoutes: RouteObject = {
  path: ROUTES.DASHBOARD,
  element: <DashboardLayout />,
  children: [
    {
      path: "",
      element: <Navigate to="auctions" replace />,
    },
    {
      path: "auctions",
      element: <DashboardAuctionsPage />,
      children: [
        {
          path: "",
          element: <Navigate to="pending" replace />,
        },
        {
          path: "pending",
          element: <PendingAuctionsPage />,
          children: [
            {
              path: ":id",
              element: <DashboardPendingAuctionDetails />,
            },
          ],
        },
        {
          path: "live",
          element: <LiveAuctionsPage />,
          children: [
            {
              path: ":id",
              element: <DashboardLiveAuctionDetails />,
            },
          ],
        },
        {
          path: "closed",
          element: <ClosedAuctionsPage />,

          children: [
            {
              path: ":id",
              element: <DashboardClosedAuctionDetails />,
            },
          ],
        },
      ],
    },
    {
      path: "bids",
      element: <DashboardBidsPage />,
      children: [
        {
          path: "",
          element: <Navigate to="won" replace />,
        },
        {
          path: "won",
          element: <WonBidsPage />,
          children: [{ path: ":id", element: <DashboardWonBidsDetails /> }],
        },
        {
          path: "active",
          element: <ActiveBidsPage />,
          children: [{ path: ":id", element: <DashboardActiveBidsDetails /> }],
        },
      ],
    },
    {
      path: "verification",
      element: <DashboardVerificationPage />,
      children: [
        {
          path: "",
          element: <Navigate to="unverified" replace />,
        },
        {
          path: "unverified",
          element: <UnverifiedAuctionsPage />,
          children: [{ path: ":id", element: <VerifyAuctionSlider /> }],
        },
        { path: "verified", element: <VerifiedByMeAuctionsPage /> },
      ],
    },
  ],
};

export default dashboardRoutes;
