import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";

interface Props {
  title?: string;
  description?: string;
  icon?: any;
  height?: string;
  okLabel?: string;
  onOk?: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
}

export const AlertScreen = (props: Props) => {
  const { title, description, icon, height, okLabel, onOk, cancelLabel, onCancel } = props;

  const {
    palette: { mode },
  } = useTheme();

  return (
    <Stack
      gap={2}
      justifyContent="center"
      alignItems="center"
      sx={{ height: height ?? "100%", mt: 2 }}
    >
      {icon}
      <Box>
        <Typography align="center" variant="h2" sx={{ color: mode === "dark" ? "#333" : "#ccc" }}>
          {title}
        </Typography>
        {description && (
          <Typography align="center" sx={{ color: mode === "dark" ? "#333" : "#ccc" }}>
            {description}
          </Typography>
        )}
      </Box>
      <Stack direction="row" gap={2}>
        {onCancel && (
          <Button onClick={onCancel} variant="outlined" color="secondary">
            {cancelLabel}
          </Button>
        )}
        {onOk && (
          <Button onClick={onOk} variant="outlined" color="secondary">
            {okLabel}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
