import React from "react";
import styled from "@mui/material/styles/styled";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import { ThemeSwitcher } from "components/ThemeSwitcher";
import blackLogo from "assets/logos/text-logo-black.png";
import whiteLogo from "assets/logos/text-logo-white.png";

import discord from "assets/icons/discord.svg";
import github from "assets/icons/github.svg";
import twitter from "assets/icons/twitter.svg";
import TelegramIcon from "@mui/icons-material/Telegram";

const FooterLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.secondary,
  fontSize: "14px",
  "&:hover": { color: theme.palette.secondary.main },
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
}));

const FooterContainer = styled("footer")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTop: "1px solid",
  borderColor: theme.palette.divider,
  padding: "48px 0px",
}));
export const Footer = () => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <FooterContainer>
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          spacing={3}
          mb={2}
        >
          <Stack spacing={3} sx={{ alignItems: { xs: "center", md: "start" } }}>
            <Link to="/">
              <img
                loading="eager"
                src={mode === "light" ? blackLogo : whiteLogo}
                width={150}
                alt="logo"
              />
            </Link>
            <Typography variant="body2">
              The first interactive NFT marketplace <br />
              with confidential sales and <br />
              customizable NFT access control.
            </Typography>
            <Stack direction="row" spacing={3} alignItems="center">
              <a href="https://twitter.com/ActiList_Dapp" target="_blank" rel="noreferrer">
                <img loading="eager" src={twitter} width={20} alt="twitter" />
              </a>
              <a href="https://t.me/actilist">
                <TelegramIcon color="primary" fontSize="small" />
              </a>
              <a href="https://discord.gg/uPxGvqJErz" target="_blank" rel="noreferrer">
                <img loading="eager" src={discord} width={20} alt="discord" />
              </a>
              <a href="https://github.com/actilabs">
                <img loading="eager" src={github} width={20} alt="github" />
              </a>
            </Stack>
          </Stack>
          <Stack spacing={2} sx={{ alignItems: { xs: "center", md: "start" } }}>
            <Typography variant="h6">Useful links</Typography>

            <FooterLink href="/">Home</FooterLink>
            <FooterLink href="/marketplace">Marketplace</FooterLink>
            <FooterLink href="/create-auction">Create auction</FooterLink>
            <FooterLink href="/dashboard">Dashboard</FooterLink>
          </Stack>

          <Stack spacing={2} sx={{ alignItems: { xs: "center", md: "start" } }}>
            <Typography variant="h6">Featured articles</Typography>
            <FooterLink href="https://docs.actilist.io" target="_blank">
              Welcome
            </FooterLink>
            <FooterLink href="https://docs.actilist.io/docs/introduction" target="_blank">
              Introduction
            </FooterLink>
            <FooterLink href="https://docs.actilist.io/docs/getting-started" target="_blank">
              Getting started
            </FooterLink>
            <FooterLink href="https://docs.actilist.io/docs/staking" target="_blank">
              Staking with us
            </FooterLink>
          </Stack>
        </Stack>
        <Container maxWidth="md">
          <Box display="flex">
            <ThemeSwitcher />
          </Box>
          <Divider />
          <Typography align="center" color="textSecondary" variant="body2" sx={{ pt: 2 }}>
            Copyrights © 2023 All rights reserved by ActiList
          </Typography>
        </Container>
      </Container>
    </FooterContainer>
  );
};
