import * as React from "react";
import { UpcomingAuctionDetails } from "modules/marketplace/upcoming-auctions/details";
import { Helmet } from "react-helmet";

const UpcomingAuctionsDetailsSlider = () => (
  <>
    <Helmet>
      <title>Marketplace | Details</title>
    </Helmet>
    <UpcomingAuctionDetails />
  </>
);

export default UpcomingAuctionsDetailsSlider;
