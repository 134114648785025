import {
  ContractDefinition,
  ContractQueryRequest,
  ContractMessageRequest,
  createContractClient,
} from "@stakeordie/griptape.js";
import { Auction } from "app/types";
import { AUCTION_FACTORY_ADDRESS } from "app/utils/constants/addresses";

interface AuctionFactory {
  listActiveAuctions(): Promise<{ list_active_auctions: { active } }>;
  listClosedAuctions(): Promise<{ list_closed_auctions: { closed } }>;
  listUnverifiedAuctions(): Promise<{
    list_unverified_auctions: { unverified };
  }>;
  listMyAuctions(): Promise<{
    list_my_auctions: { address: string; viewing_key: string };
  }>;
  listMyNftCollections(): Promise<{
    list_my_nft_collections: { address: string; viewing_key: string };
  }>;
  // eslint-disable-next-line
  getAuctionInfo(): Promise<{ auction_info: {} }>;
  createViewingKey(): Promise<any>;
  createAuction(auction: Auction, fees: number): Promise<any>;
}

const auctionFactoryDef: ContractDefinition = {
  queries: {
    listActiveAuctions(): ContractQueryRequest {
      return { list_active_auctions: {} };
    },

    listClosedAuctions(): ContractQueryRequest {
      return { list_closed_auctions: {} };
    },

    listUnverifiedAuctions(): ContractQueryRequest {
      return { list_unverified_auctions: {} };
    },

    getAuctionInfo(): ContractQueryRequest {
      return { auction_info: {} };
    },

    listMyAuctions({ address, key }): ContractQueryRequest {
      return { list_my_auctions: { address, viewing_key: key } };
    },

    listMyNftCollections({ address, key: viewing_key }): ContractQueryRequest {
      return { list_my_nft_collections: { address, viewing_key } };
    },
  },

  messages: {
    createViewingKey({ entropy }): ContractMessageRequest {
      const handleMsg = {
        create_viewing_key: {
          entropy,
        },
      };
      return { handleMsg };
    },

    createAuction(_ctx, auction: Auction, fees: number): ContractMessageRequest {
      const handleMsg = {
        create_auction: auction,
      };
      return { handleMsg, fees }; // 1500000
    },
  },
};

export const AuctionFactoryContractClient = createContractClient<AuctionFactory>({
  id: "af4708",
  at: AUCTION_FACTORY_ADDRESS,
  definition: auctionFactoryDef,
});
