const typography = {
  fontFamily: "Poppins , sans-serif",
  fontWeightBold: 800,
  fontSize: 16,

  h1: {
    fontWeight: 800,
    fontSize: 85,
    "@media (max-width:700px)": {
      fontSize: 40,
    },
  },
  h2: {
    fontSize: 50,
    fontWeight: 600,
    "@media (max-width:700px)": {
      fontSize: 26,
    },
  },
  h3: {
    fontSize: 40,
    fontWeight: 600,
    "@media (max-width:600px)": {
      fontSize: 28,
    },
  },
  h4: {
    fontSize: 21,
    fontWeight: 600,
  },
  h5: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1,
  },
  h6: {
    fontSize: 18,
    fontWeight: 600,
    "@media (max-width:600px)": {
      fontSize: 15,
    },
  },
  subtitle1: {
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: "0.5px",
  },
  subtitle2: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: "0.5px",
  },

  body1: {
    fontSize: 16,
    fontWeight: "500",
    "@media (max-width:700px)": {
      fontSize: 14,
    },
  },
  body2: {
    fontSize: 14,
    fontWeight: "500",
    "@media (max-width:700px)": {
      fontSize: 13,
    },
  },
};

export default typography;

/* const typography = {
    fontFamily: 'Poppins , sans-serif',
    h1: {
        fontSize: '5rem',
        fontWeight: 800,
        lineHeight: 1.2,
        letterSpacing: '0.0075em',
        textTransform: 'uppercase'
    },
    h2: {
        fontSize: '2.5rem',
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: '0.0075em',
        textTransform: 'uppercase'
    },
    h3: {
        fontSize: '2rem',
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: '0.0075em',
        textTransform: 'uppercase'
    },
    h4: {
        fontSize: '1.75rem',
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: '0.0075em'
    },
    h5: {
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: '0.0075em'
    },
    h6: {
        fontSize: '1.25rem',
        fontWeight: 700,
        lineHeight: 1.2,
        letterSpacing: '0.0075em'
    },
    body1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.5,
        letterSpacing: '0.00938em'
    },
    body2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.43,
        letterSpacing: '0.01071em'
    },
    body3: {
        fontSize: '0.5rem',
        fontWeight: 400,
        lineHeight: 1.43,
        letterSpacing: '0.01071em'
    }
};
export default typography;
 */
