import { saveItemToLS, getItemFromLS } from "app/utils/_helpers/localstorage";
import { getAddress } from "@stakeordie/griptape.js";
import { LOADING } from "app/utils/constants/others";
import { profileInfoInitState } from "./state";

export const getExistingAuthLS = () => {
  const address = getAddress();
  const currentJwts = getItemFromLS("acti-jwt") ?? undefined;

  if (!currentJwts) throw new Error("No logged user !");
  const existing = currentJwts.find((item) => item.address === address);

  return existing?.jwt;
};

export const setAuthLS = (jwt: string) => {
  const address = getAddress();
  const currentJwts = getItemFromLS("acti-jwt") ?? undefined;

  if (!currentJwts) {
    saveItemToLS("acti-jwt", [{ address, jwt }]);
  } else {
    saveItemToLS("acti-jwt", [...currentJwts, { address, jwt }]);
  }
};

export const clearAuthLS = () => {
  const address = getAddress();
  const currentJwts = getItemFromLS("acti-jwt") ?? undefined;
  const currentVks = getItemFromLS("griptape.js") ?? undefined;

  const updatedJwts = currentJwts.filter((item) => item.address !== address);
  saveItemToLS("acti-jwt", updatedJwts);

  const updatedVks = currentVks.filter((item) => item.address !== address);
  saveItemToLS("griptape.js", updatedVks);
};

export const updatedLoggedUserState = (state, source) => {
  // eslint-disable-next-line
  const { profile_photo, cover_photo } = source;
  const baseUrl = process.env.REACT_APP_API_HOST;
  const updatedProfile = {};
  const updatedLoading = { ...state.loading, getProfile: LOADING.SUCCEEDED };
  const updatedMedia = {
    profile_photo: profile_photo?.url ? `${baseUrl}${profile_photo?.url}` : null,
    cover_photo: cover_photo?.url ? `${baseUrl}${cover_photo?.url}` : null,
  };

  // eslint-disable-next-line
  Object.keys(profileInfoInitState).map((key) => {
    // eslint-disable-next-line
    if (source.hasOwnProperty(key)) {
      updatedProfile[key] = source[key];
    }
  });

  return {
    ...state,
    userId: source.id,
    profileInfo: updatedProfile,
    profileMedia: updatedMedia,
    loading: updatedLoading,
    error: null,
  };
};
