import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auctions } from "infrastructure/services/blockchain/auctions";
import { filterActiveAuctions } from "app/utils/_helpers/auctions";
import { LiveAuction, ClosedAuctions, UpcomingAuction } from "app/types/auctions";

export const listLiveAuctions = createAsyncThunk<Array<LiveAuction>>("list-live", async () => {
  const {
    list_active_auctions: { active },
  } = await Auctions.listActiveAuctions();
  const { live } = filterActiveAuctions(active);

  return live as Array<LiveAuction>;
});

export const listClosedAuctions = createAsyncThunk<Array<ClosedAuctions>>(
  "list-closed",
  async () => {
    const {
      list_closed_auctions: { closed },
    } = await Auctions.listClosedAuctions();

    return closed as Array<ClosedAuctions>;
  }
);

export const listUpcomingAuctions = createAsyncThunk<Array<UpcomingAuction>>(
  "list-upcoming",
  async () => {
    const {
      list_active_auctions: { active },
    } = await Auctions.listActiveAuctions();
    const { upcoming } = filterActiveAuctions(active);

    return upcoming as Array<UpcomingAuction>;
  }
);
