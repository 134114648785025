import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  children: string | string[];
  style?: any;
}

export const TextSmallSecondary = (props: Props) => {
  const { children, style } = props;
  return (
    <Typography color="text.secondary" sx={{ fontSize: "13px", ...style }}>
      {children}
    </Typography>
  );
};

export const TextSmallPrimary = (props: Props) => {
  const { children, style } = props;
  return <Typography sx={{ fontSize: "13px", ...style }}>{children}</Typography>;
};
