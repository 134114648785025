import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import NavLink from "components/NavLink";
import { AuthButton } from "components/AuthButton";
import WalletButton from "components/WalletButton";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { logout } from "infrastructure/store/slices/authentication";
import { ROUTES } from "app/utils/constants/routes";

export const MobileSidebar = () => {
  const { isAuthenticated } = useAppSelector((s) => s.auth);
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Stack direction="column" alignItems="center" mt={15}>
      <TreeView
        aria-label="file system navigator"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          maxWidth: 400,
          overflowY: "auto",
          "& .MuiSelected": { backgroundColor: "transparent" },
          "&  .MuiTreeItem-root": { mb: 2 },
          "&  .MuiTreeItem-label": { color: "text.secondary" },
        }}
      >
        <TreeItem nodeId="1" label="Profile">
          <TreeItem nodeId="2" label={<NavLink to={ROUTES.PROFILE_ACCOUNT} label="Account" />} />
          <TreeItem nodeId="3" label={<NavLink to={ROUTES.PROFILE_ASSETS} label="Assets" />} />
          <TreeItem nodeId="4" label={<NavLink to={ROUTES.PROFILE_REWARDS} label="Rewards" />} />
        </TreeItem>
        <TreeItem nodeId="5" label="Dashboard">
          <TreeItem
            nodeId="10"
            label={<NavLink to={ROUTES.DASHBOARD_AUCTIONS} label="Auctions" />}
          />
          <TreeItem nodeId="11" label={<NavLink to={ROUTES.DASHBOARD_BIDS} label="Bids" />} />
          <TreeItem
            nodeId="12"
            label={<NavLink to={ROUTES.DASHBOARD_VERIFICATION} label="Verifications" />}
          />
        </TreeItem>
        <TreeItem
          nodeId="13"
          label={<NavLink to={ROUTES.MARKETPLACE_LIVE} label="Marketplace" />}
        />
        {/*  <TreeItem nodeId="14" label={<NavLink to={ROUTES.MINT} label="Mint" />} /> */}
        <TreeItem nodeId="15" label={<NavLink to={ROUTES.CREATE_AUCTION} label="Create" />} />
        <TreeItem nodeId="16" label={<NavLink to={ROUTES.ROADMAP} label="MVP Roadmap" />} />
      </TreeView>

      <Stack direction="column" alignItems="center" spacing={2} mt={2}>
        <WalletButton />
        {isAuthenticated ? (
          <Button onClick={handleLogout} variant="outlined" color="secondary">
            Logout
          </Button>
        ) : (
          <AuthButton />
        )}
      </Stack>
    </Stack>
  );
};
