export const ROUTES = {
  // marketplace
  MARKETPLACE_LIVE: "/marketplace/live",
  MARKETPLACE_UPCOMING: "/marketplace/upcoming",
  MARKETPLACE_CLOSED: "/marketplace/closed",

  // dashboard
  DASHBOARD: "/dashboard",
  DASHBOARD_AUCTIONS: "/dashboard/auctions",
  DASHBOARD_BIDS: "/dashboard/bids",
  DASHBOARD_VERIFICATION: "/dashboard/verification",

  // profile
  PROFILE: "/profile",
  PROFILE_ACCOUNT: "/profile/account",
  PROFILE_ASSETS: "/profile/assets",
  PROFILE_REWARDS: "/profile/rewards",

  // create auction
  CREATE_AUCTION: "/create-auction",

  // roadmap
  ROADMAP: "/roadmap",

  // mint
  MINT: "/mint",

  // swap
  SWAP: "/swap",
};
