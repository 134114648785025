import React, { lazy } from "react";
import { Outlet, RouteObject } from "react-router";
import { RoutesLoader } from "components/RoutesLoader";

import { FullScreenLayout } from "../../layouts/FullScreenLayout";

const Home = RoutesLoader(lazy(() => import("pages/home")));
const CreateAuction = RoutesLoader(lazy(() => import("pages/create-auction")));
const Mint = RoutesLoader(lazy(() => import("pages/mint")));
const TokenSwap = RoutesLoader(lazy(() => import("pages/token-swap")));
const Roadmap = RoutesLoader(lazy(() => import("pages/roadmap")));
const Error = RoutesLoader(lazy(() => import("pages/error")));

export const homeRoute: RouteObject = {
  path: "/",
  element: <FullScreenLayout />,
  children: [{ path: "", element: <Home /> }],
};

export const mintRoute: RouteObject = {
  path: "/mint",
  element: <FullScreenLayout />,
  children: [{ path: "/mint", element: <Mint /> }],
};

export const tokenSwapRoute: RouteObject = {
  path: "/swap",
  element: <FullScreenLayout />,
  children: [{ path: "/swap", element: <TokenSwap /> }],
};

export const roadmapRoute: RouteObject = {
  path: "/roadmap",
  element: <FullScreenLayout />,
  children: [{ path: "/roadmap", element: <Roadmap /> }],
};

export const createAuctionRoute: RouteObject = {
  path: "/create-auction",
  element: <Outlet />,
  children: [{ path: "/create-auction", element: <CreateAuction /> }],
};

export const errorRoute: RouteObject = {
  path: "*",
  element: <Error />,
};
