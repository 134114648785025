import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auctions } from "infrastructure/services/blockchain/auctions";
import { AuctionsService } from "infrastructure/services/server/auctions";

const listFailedAuctionsAddresses = async () => {
  const {
    data: { data },
  } = await AuctionsService.listFailedAuctions();
  return data;
};

export const listMyAuctions = createAsyncThunk("my-auctions", async () => {
  // eslint-disable-next-line
  const { list_my_auctions } = await Auctions.listMyAuctions();
  return list_my_auctions;
});

const filterUnverifiedAuctions = (all, failed) => {
  const res = all.filter(({ address }) => {
    // eslint-disable-next-line
    for (let i = 0; i < failed.length; i++) {
      if (address === failed[i].attributes.title) {
        return false;
      }
    }
    return true;
  });

  return res;
};

export const listUnverifiedAuctions = createAsyncThunk("unverified-uctions", async () => {
  const {
    list_unverified_auctions: { unverified },
  } = await Auctions.listUnverifiedAuctions();

  const failedAuctionsAddresses = await listFailedAuctionsAddresses();
  const res = filterUnverifiedAuctions(unverified, failedAuctionsAddresses);

  return res;
});
