import * as React from "react";
import { Box, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { coinConvert } from "@stakeordie/griptape.js";
import { toast } from "react-toastify";
import { placeBid } from "infrastructure/store/slices/auction-details";
import { listMyAuctions } from "infrastructure/store/slices/dashboard";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";

interface Props {
  auctionAddress: string;
  minimumBid: string;
  bidContractAddress: string;
  isOwner: boolean;
}

export const PlaceBid = (props: Props) => {
  const { auctionAddress, minimumBid, bidContractAddress, isOwner } = props;

  const state = useAppSelector((s) => s.auctionDetails);
  const dispatch = useAppDispatch();

  const [bid, setBid] = React.useState(null);

  const handleBidChange = (event) => {
    event.preventDefault();
    setBid(event.target.value);
  };

  const minBidError = (minBid) => {
    const amount = coinConvert(minBid, 6, "human");
    toast.error(`Bid should be equal or bigger than ${amount} sSCRT`);
  };

  const handlePlaceBid = async (): Promise<void> => {
    if (!bid) return;

    const amount = coinConvert(bid, 6, "machine");
    if (amount < minimumBid) {
      minBidError(minimumBid);
      return;
    }
    await dispatch(
      placeBid({
        bid_contract_address: bidContractAddress,
        auction_address: auctionAddress,
        amount,
      })
    );
    dispatch(listMyAuctions());
  };

  if (isOwner) return null;
  return (
    <Box display="flex" gap={2}>
      <TextField size="small" variant="outlined" fullWidth onChange={handleBidChange} />

      <LoadingButton
        sx={{ px: 4 }}
        loading={state.actionsLoading === "place-bid"}
        onClick={handlePlaceBid}
        color="primary"
        variant="contained"
        size="medium"
      >
        Bid
      </LoadingButton>
    </Box>
  );
};
