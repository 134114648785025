import { createSlice } from "@reduxjs/toolkit";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import {
  createAuction,
  approveNftAccess,
  revokeNftAccess,
  getCollectionCodeHash,
  createCollectionVk,
  importCollectionNfts,
} from "./reducers";
import { initialState } from "./state";

const createAuctionSlice = createSlice({
  name: "createAuction",
  initialState,
  reducers: {
    selectNFT: (state, action) => {
      state.selectedNft = action.payload;
    },

    resetImportedCollection: (state) => {
      state.importedCollection = initialState.importedCollection;
    },

    changeDisclaimer: (state, action) => {
      state.isDisclaimerAccepted = action.payload;
    },

    setNftAccessApproval: (state, action) => {
      state.isSelectedNftAccessApproved = action.payload;
    },
    removeNftFromCollection: (state, action) => {
      state.importedCollection.collection_nfts = state.importedCollection.collection_nfts.filter(
        (nft) => nft.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCollectionVk.pending, (state) => {
      state.loading.createCollectionVk = LOADING.PENDING;
    });
    builder.addCase(createCollectionVk.fulfilled, (state) => {
      state.loading.createCollectionVk = LOADING.SUCCEEDED;
    });
    builder.addCase(createCollectionVk.rejected, (state, action) => {
      state.error = action.error;
      state.loading.createCollectionVk = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(getCollectionCodeHash.pending, (state) => {
      state.loading.getCodeHash = LOADING.PENDING;
    });
    builder.addCase(getCollectionCodeHash.fulfilled, (state, action) => {
      state.auction.sell_contract.code_hash = action.payload;
      state.loading.getCodeHash = LOADING.SUCCEEDED;
    });
    builder.addCase(getCollectionCodeHash.rejected, (state, action) => {
      state.error = action.error;
      state.loading.getCodeHash = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(importCollectionNfts.pending, (state) => {
      state.loading.importCollection = LOADING.PENDING;
    });
    builder.addCase(importCollectionNfts.fulfilled, (state, action) => {
      state.importedCollection = action.payload;
      state.auction.sell_contract.address = action.payload.collection_address;
      state.loading.importCollection = LOADING.SUCCEEDED;
      if (action.payload.notify) {
        toast.success("Nfts successfully imported.");
      }
    });
    builder.addCase(importCollectionNfts.rejected, (state, action) => {
      state.loading.importCollection = LOADING.FAILED;
      state.error = action.error;
      toast.error(action.error.message);
    });

    builder.addCase(createAuction.pending, (state) => {
      state.loading.createAuction = LOADING.PENDING;
    });
    builder.addCase(createAuction.fulfilled, (state) => {
      state.loading.createAuction = LOADING.SUCCEEDED;
      toast.success("Auction successfully created ");
    });
    builder.addCase(createAuction.rejected, (state, action) => {
      state.loading.createAuction = LOADING.FAILED;
      state.error = action.error;
      toast.error(action.error.message);
    });

    builder.addCase(approveNftAccess.pending, (state) => {
      state.loading.approveNftAccess = LOADING.PENDING;
    });
    builder.addCase(approveNftAccess.fulfilled, (state) => {
      state.loading.approveNftAccess = LOADING.SUCCEEDED;
      state.isSelectedNftAccessApproved = true;
      toast.success("Your NFT is accessible by ActiList.");
    });
    builder.addCase(approveNftAccess.rejected, (state, action) => {
      state.loading.approveNftAccess = LOADING.FAILED;
      state.error = action.error;
      toast.error(action.error.message);
    });

    builder.addCase(revokeNftAccess.pending, (state) => {
      state.loading.revokeNftAccess = LOADING.PENDING;
    });
    builder.addCase(revokeNftAccess.fulfilled, (state) => {
      state.loading.revokeNftAccess = LOADING.SUCCEEDED;
      state.isSelectedNftAccessApproved = false;
      toast.success("Your NFT is no more accessible by ActiList");
    });
    builder.addCase(revokeNftAccess.rejected, (state, action) => {
      state.loading.revokeNftAccess = LOADING.FAILED;
      state.error = action.error;
      toast.error(action.error.message);
    });
  },
});

export {
  createAuction,
  approveNftAccess,
  revokeNftAccess,
  getCollectionCodeHash,
  createCollectionVk,
  importCollectionNfts,
};

export const {
  selectNFT,
  resetImportedCollection,
  changeDisclaimer,
  setNftAccessApproval,
  removeNftFromCollection,
} = createAuctionSlice.actions;

export default createAuctionSlice.reducer;
