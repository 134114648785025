import { AuctionFactoryContractClient } from "infrastructure/Contract/auction-factory";
import { AuctionContract } from "infrastructure/Contract/auction";
import { BidContract } from "infrastructure/Contract/snip20";
import { Auction, AuctionVerification } from "app/types";
import { viewingKeyManager } from "@stakeordie/griptape.js";
import { AUCTION_FACTORY_ADDRESS } from "app/utils/constants/addresses";

export class Auctions {
  // QUERIES
  static listActiveAuctions() {
    return AuctionFactoryContractClient.listActiveAuctions();
  }

  static listClosedAuctions() {
    return AuctionFactoryContractClient.listClosedAuctions();
  }

  static listMyAuctions() {
    return AuctionFactoryContractClient.listMyAuctions();
  }

  static listUnverifiedAuctions() {
    return AuctionFactoryContractClient.listUnverifiedAuctions();
  }

  static getAuctionInfo(auction_address: string) {
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.getAuctionInfo();
  }

  static getPlacedBid(wallet_address: string, auction_address: string, vk: string) {
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.getPlacedBid(wallet_address, vk);
  }

  static hasBids(auction_address: string) {
    const vk = viewingKeyManager.get(AUCTION_FACTORY_ADDRESS);
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.hasBids(auction_address, vk);
  }

  // MESSAGES
  static placeBid(bid_contract_address: string, auction_address: string, amount: string) {
    const BidContractClient = BidContract(bid_contract_address);
    return BidContractClient.send(auction_address, amount);
  }

  static changeMinimumBid(auction_address: string, amount: string, fees: number) {
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.changeMinimumBid(amount, fees);
  }

  static retractBid(auction_address: string, fees: number) {
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.retractBid(fees);
  }

  static finalize(auction_address: string, fees: number) {
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.finalize(fees);
  }

  static createAuction(auction: Auction, fees: number) {
    return AuctionFactoryContractClient.createAuction(auction, fees);
  }

  static verifyAuction(auction_address: string, verification: AuctionVerification, fees: number) {
    const AuctionContractClient = AuctionContract(auction_address);
    return AuctionContractClient.verify(verification, fees);
  }
}
