import { LOADING } from "app/utils/constants/others";

interface InitialStateProps {
  allAuctions: any;
  liveAuctions: Array<any> | null;
  pendingAuctions: Array<any> | null;
  closedAuctions: Array<any> | null;
  activeBids: Array<any> | null;
  wonBids: Array<any> | null;
  unverifiedAuctions: Array<any>;
  verifiedByMeAuctions: Array<any>;
  loading: any;
  errors: any | null;
}

export const initialState: InitialStateProps = {
  allAuctions: null,
  liveAuctions: null,
  pendingAuctions: null,
  closedAuctions: null,
  activeBids: null,
  wonBids: null,
  unverifiedAuctions: [],
  verifiedByMeAuctions: [],
  loading: {
    myAuctions: LOADING.IDLE,
    unverified: LOADING.IDLE,
  },
  errors: null,
};
