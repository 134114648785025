import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import Header from "./components/Header";
import { MobileSidebar } from "./components/MobileSidebar";

const Alert = () => (
  <ToastContainer
    position="bottom-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    draggable
    pauseOnHover
    // theme="colored"
    // transition={Zoom}
  />
);

const TemporaryDrawerStyles = {
  display: { xs: "block", md: "none" },

  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    width: "80%",
  },
};

interface Props {
  children: any;
}

export const BaseLayout = React.memo((props: Props) => {
  const { children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  React.useEffect(() => {
    setMobileOpen(false);
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex", flex: 1 }}>
      <CssBaseline />
      <Header handleDrawerToggle={handleDrawerToggle} />

      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={TemporaryDrawerStyles}
      >
        <MobileSidebar />
      </Drawer>

      <Box component="main" flexGrow={1} sx={{ minHeight: "100vh" }}>
        {children}
      </Box>

      <Alert />
    </Box>
  );
});
