import React, { useEffect, useState } from "react";
import SlideDrawer from "components/SlideDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { Details } from "modules/auctions-details/live";
import { extractAddressFromUrl } from "app/utils/_helpers";
import { ROUTES } from "app/utils/constants/routes";

import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { selectNextAuction, selectPrevAuction } from "infrastructure/store/slices/marketplace";

export const LiveAuctionDetails = () => {
  const [open, setOpen] = useState(false);
  const { selectedAuction, liveAuctions } = useAppSelector((state) => state.marketplace);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const auctionAddress = selectedAuction?.address ?? extractAddressFromUrl(location.pathname);

  const goback = () => {
    setOpen(false);
    setTimeout(() => navigate(ROUTES.MARKETPLACE_LIVE), 300);
  };

  const updateUrl = (address: string) => {
    const url = `${ROUTES.MARKETPLACE_LIVE}/${address}`;
    navigate(url, { replace: true });
  };

  const onClickNext = () => {
    dispatch(selectNextAuction(liveAuctions));
    updateUrl(auctionAddress);
  };

  const onClickPrev = () => {
    dispatch(selectPrevAuction(liveAuctions));
    updateUrl(auctionAddress);
  };

  useEffect(() => {
    setOpen(true);
  }, [auctionAddress]);

  return (
    <SlideDrawer
      open={open}
      toggleDrawer={goback}
      onClickNext={onClickNext}
      onClickPrev={onClickPrev}
    >
      <Details auctionAddress={auctionAddress} />
    </SlideDrawer>
  );
};
