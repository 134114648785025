import * as React from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";

import { LOADING } from "app/utils/constants/others";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { getAuctionInfo } from "infrastructure/store/slices/auction-details";
import { Loading } from "components/Loading";
import { AuctionNFT } from "../components/AuctionNFT";
import { AuctionMetadata } from "../components/AuctionMetadata";
import { AuctionInfo } from "../components/AuctionInfo";

interface Props {
  auctionAddress: string;
}

export const Details = (props: Props) => {
  const { auctionAddress } = props;

  const { auction, loading } = useAppSelector((state) => state.auctionDetails);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAuctionInfo(auctionAddress));
  }, [auctionAddress, dispatch]);

  if (loading.getAuctionInfo === LOADING.PENDING) {
    return <Loading />;
  }

  return (
    <Box display={{ xs: "block", md: "flex" }} gap={4}>
      <Box flex={2}>
        <AuctionNFT nftUrl={auction?.image_url} />
      </Box>

      <Box flex={3}>
        <AuctionMetadata
          status={auction?.auction_status}
          name={auction?.name}
          endsAtTimestamp={auction?.ends_at_timestamp}
          description={auction?.description}
        />

        <AuctionInfo
          minimumBid={auction?.minimum_bid}
          endsAt={auction?.ends_at}
          winningBid={auction?.winning_bid}
        />
      </Box>
    </Box>
  );
};
