import { LOADING } from "app/utils/constants/others";

interface Loading {
  connect: string;
  login: string;
  logout: string;
  currentAuthenticated: string;
}

interface Auth {
  walletName: string;
  walletAddress: string;
  authVk: string;
  jwt: string;
  isAuthenticated: boolean;
  isWalletConnected: boolean;
  loading: Loading;
  error: any;
}

export const initialState: Auth = {
  walletName: "",
  walletAddress: "",
  authVk: "",
  jwt: "",
  isAuthenticated: false,
  isWalletConnected: false,
  loading: {
    connect: LOADING.IDLE,
    login: LOADING.IDLE,
    logout: LOADING.IDLE,
    currentAuthenticated: LOADING.IDLE,
  },
  error: null,
};
