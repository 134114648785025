import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const drawerWidth = 240;

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const FilterBtnWrapper = styled(Box)({
  position: "absolute",
  left: -32,
  top: "300px",
});

export const FilterBtn = styled(Button)({
  transform: "rotate(90deg)",
  position: "fixed",
  "& .MuiSvgIcon-root": {
    transform: "rotate(270deg)",
  },
});

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  position: "relative",
  padding: theme.spacing(3),
  paddingTop: "100px",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    padding: theme.spacing(1),
  },
  minHeight: "100vh",
  // marginTop: '100px',
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));
