import * as React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { useRoutes } from "react-router-dom";
import { BaseLayout } from "layouts/BaseLayout";
import { useAppDispatch, useAppSelector } from "infrastructure/store/hooks";
import {
  currentAuthenticatedUser,
  setWalletInfo,
} from "infrastructure/store/slices/authentication";
import { onAccountAvailable } from "@stakeordie/griptape.js";
import { getProfileAndAddIfNotFound, initUserAssets } from "infrastructure/store/slices/user";
import { getNativeBalance, getWrappedBalance } from "infrastructure/store/slices/tokens";
import routes from "./router";
import { SettingsProvider } from "./app/context/settingsContext";

const App = () => {
  const content = useRoutes(routes);

  const dispatch = useAppDispatch();

  const { isWalletConnected, jwt } = useAppSelector((s) => s.auth);

  React.useEffect(() => {
    if (isWalletConnected) {
      dispatch(getNativeBalance());
      dispatch(getWrappedBalance());
    }
  }, [isWalletConnected, dispatch]);

  React.useEffect(() => {
    if (jwt) {
      dispatch(getProfileAndAddIfNotFound());
      dispatch(initUserAssets());
    }

    const stopListen = onAccountAvailable(() => {
      dispatch(setWalletInfo());
      dispatch(currentAuthenticatedUser());
    });

    return () => {
      stopListen();
    };
  }, [jwt, dispatch]);

  return (
    <SettingsProvider>
      <ParallaxProvider>
        <BaseLayout>{content}</BaseLayout>
      </ParallaxProvider>
    </SettingsProvider>
  );
};

export default App;
