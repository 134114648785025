import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthService } from "infrastructure/services/blockchain/auth";
import {
  viewingKeyManager,
  coinConvert,
  getNativeCoinBalance,
  refContract,
  Snip20Contract,
} from "@stakeordie/griptape.js";
import { SNIP20_ADDRESS } from "app/utils/constants/addresses";
import { BidContract } from "infrastructure/Contract/snip20";

export const setTokenVk = createAsyncThunk("set-token-vk", async () => {
  const vk = await AuthService.getSecret20Vk();
  if (!vk) throw new Error("can't get token viewing key !");

  const contract = BidContract(SNIP20_ADDRESS);
  viewingKeyManager.set(contract, vk);
  await AuthService.setTokenVK(vk);
  return vk;
});

export const createTokenVK = createAsyncThunk<string>("create-token-vk", async () => {
  const res = await AuthService.createTokenVK();
  if (res.isEmpty()) throw new Error("can't create token viewing key !");

  const {
    create_viewing_key: { key },
  } = res.parse();

  const contract = refContract<Snip20Contract>(SNIP20_ADDRESS);
  viewingKeyManager.add(contract, key);
  return key;
});

export const getNativeBalance = createAsyncThunk<string>("get-n-balance", async () => {
  let balance = await getNativeCoinBalance();
  balance = coinConvert(balance, 6, "human");
  return balance;
});

export const getWrappedBalance = createAsyncThunk<string>("get-w-balance", async () => {
  const vk = await AuthService.getSecret20Vk();
  const contract = BidContract(SNIP20_ADDRESS);
  viewingKeyManager.add(contract, vk);

  const {
    balance: { amount: result },
  } = await AuthService.getWrappedBalance();
  const res = coinConvert(result, 6, "human");
  return res;
});

export const swapToken = createAsyncThunk<void, { amount: string; toWrapped: boolean }>(
  "swap",
  async ({ amount, toWrapped }) => {
    if (toWrapped) {
      await AuthService.deposit(amount);
    } else {
      await AuthService.redeem(amount);
    }
  }
);
