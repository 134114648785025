import * as React from "react";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";

import { LOADING } from "app/utils/constants/others";
import { Loading } from "components/Loading";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import {
  getAuctionInfo,
  isAuctionOwner,
  isAuctionHasBids,
} from "infrastructure/store/slices/auction-details";
import { FinalizeAuction } from "../components/FinalizeAuction";
import { PlaceBid } from "../components/PlaceBid";
import { AuctionInfo } from "../components/AuctionInfo";
import { AuctionMetadata } from "../components/AuctionMetadata";
import { RetractBid } from "../components/RetractBid";
import { ChangeMinimumBid } from "../components/ChangeMinimumBid";
import { AuctionNFT } from "../components/AuctionNFT";
import { NftMetadata } from "../components/NftMetadata";

interface Props {
  auctionAddress: string;
}

export const Details = (props: Props) => {
  const { auctionAddress } = props;

  const state = useAppSelector((s) => s.auctionDetails);
  const dispatch = useAppDispatch();
  // eslint-disable-next-line
  const [hasBids, setHasBids] = useState<boolean>(false);
  // eslint-disable-next-line
  const [isOwner, setIsOwner] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAuctionInfo(auctionAddress));
    dispatch(isAuctionOwner(auctionAddress));
    dispatch(isAuctionHasBids(auctionAddress));
  }, [auctionAddress, dispatch]);

  if (state.loading.getAuctionInfo === LOADING.PENDING) {
    return <Loading />;
  }

  const { auction } = state;

  return (
    <Box display={{ xs: "block", md: "flex" }} gap={4}>
      <Box flex={2}>
        <AuctionNFT nftUrl={auction?.image_url} />
        <NftMetadata auction={auction} />
      </Box>

      <Box flex={3}>
        <AuctionMetadata
          status={auction?.auction_status}
          name={auction?.name}
          endsAtTimestamp={auction?.ends_at_timestamp}
          description={auction?.description}
          hasBids={hasBids}
          isOwner={state?.isOwner}
        />

        <AuctionInfo minimumBid={auction?.minimum_bid} endsAt={auction?.ends_at} />

        <PlaceBid
          auctionAddress={auctionAddress}
          bidContractAddress={auction?.bid_contract_address}
          minimumBid={auction?.minimum_bid}
          isOwner={state?.isOwner}
        />

        <ChangeMinimumBid
          auctionAddress={auctionAddress}
          isOwner={isOwner}
          /* getAuctionInfo={getAuctionInfo} */
        />

        <RetractBid auctionAddress={auctionAddress} />

        <FinalizeAuction
          auctionAddress={auctionAddress}
          isOwner={state?.isOwner}
          isEnded={auction?.auction_status?.isEnded}
        />
      </Box>
    </Box>
  );
};
