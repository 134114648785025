import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import { RoutesLoader } from "components/RoutesLoader";

// layout
import { ProfileLayout } from "layouts/ProfileLayout";

// pages
const AccountPage = RoutesLoader(lazy(() => import("pages/profile/account")));
const AssetsPage = RoutesLoader(lazy(() => import("pages/profile/assets")));
const RewardsPage = RoutesLoader(lazy(() => import("pages/profile/rewards")));
const BadgeDetailsPage = RoutesLoader(lazy(() => import("pages/profile/badge-details")));

const profileRoutes: RouteObject = {
  path: "/profile",
  element: <ProfileLayout />,
  children: [
    {
      path: "",
      element: <Navigate to="account" replace />,
    },
    {
      path: "account",
      element: <AccountPage />,
    },
    {
      path: "assets",
      element: <AssetsPage />,
    },
    {
      path: "rewards",
      element: <RewardsPage />,
    },
    {
      path: "rewards/:id",
      element: <BadgeDetailsPage />,
    },
  ],
};

export default profileRoutes;
