import { LOADING } from "app/utils/constants/others";

interface Token {
  label: string;
  address: string;
  nativeBalance: string;
  wrappedBalance: string;
  viewingKey: string;
  symbol: string;
  hashCode: string;
  decimals: number;
}

interface Tokens {
  token: Token;
  loading: {
    swap: string;
    viewingKey: string;
    nativeBalance: string;
    wrappedBalance: string;
  };
  error: any;
}

export const initialState: Tokens = {
  token: {
    nativeBalance: "",
    wrappedBalance: "",
    viewingKey: "",
    label: "sSCRT",
    symbol: "SSCRT",
    address: "secret18vd8fpwxzck93qlwghaj6arh4p7c5n8978vsyg",
    hashCode: "9587d60b8e6b078ace12014ceeee089530b9fabcd76535d93666a6c127ad8813",
    decimals: 6,
  },

  loading: {
    swap: LOADING.IDLE,
    viewingKey: LOADING.IDLE,
    nativeBalance: LOADING.IDLE,
    wrappedBalance: LOADING.IDLE,
  },
  error: null,
};
