import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import DateTimePicker from "components/DateTimePicker";

import { useNavigate, useLocation } from "react-router-dom";
import { InputLabel } from "components/InputLabel";
import { ROUTES } from "app/utils/constants/routes";

const AUCTION_TYPES = {
  LIVE: "live",
  CLOSED: "closed",
  UPCOMING: "upcoming",
};

export const SidebarFilters = React.memo(() => {
  const [auctionType, setAuctionType] = React.useState<string>("");
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const auctionTypeChange = (event) => {
    setAuctionType(event.target.value);
    navigate(`/marketplace/${event.target.value}`);
  };

  React.useEffect(() => {
    if (pathname.includes(ROUTES.MARKETPLACE_LIVE)) {
      setAuctionType(AUCTION_TYPES.LIVE);
    } else if (pathname.includes(ROUTES.MARKETPLACE_CLOSED)) {
      setAuctionType(AUCTION_TYPES.CLOSED);
    } else if (pathname.includes(ROUTES.MARKETPLACE_UPCOMING)) {
      setAuctionType(AUCTION_TYPES.UPCOMING);
    }
  }, []);

  return (
    <Stack direction="column" spacing={3} sx={{ ml: 2 }}>
      <Stack>
        <InputLabel> Status</InputLabel>
        <FormControl fullWidth>
          <Select value={auctionType} onChange={auctionTypeChange}>
            <MenuItem value={AUCTION_TYPES.LIVE}>Live</MenuItem>
            <MenuItem value={AUCTION_TYPES.CLOSED}>Closed</MenuItem>
            <MenuItem value={AUCTION_TYPES.UPCOMING}>Upcoming</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <Stack>
        <InputLabel>Minimum bid</InputLabel>
        <Slider disabled color="secondary" />
      </Stack>

      <Stack>
        <InputLabel>Time range</InputLabel>
        <DateTimePicker selected={new Date()} />
      </Stack>
      <Box />

      <Button fullWidth color="secondary">
        Reset
      </Button>
    </Stack>
  );
});
