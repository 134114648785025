/* eslint-disable */
import * as React from "react";
import { Drawer, Box, Button } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useStyles } from "./styles";

const SlideDrawer = ({ children, ...props }) => {
  const { open, toggleDrawer, onClickPrev, onClickNext } = props;
  const classes = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer} className={classes.root}>
      <Box
        zIndex={1000}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={0.5}
        pl={2}
        pr={2}
      >
        <Button onClick={toggleDrawer} color="secondary" startIcon={<ArrowLeftIcon />}>
          Back
        </Button>

        <Box display="flex" gap={2}>
          <Button onClick={onClickPrev} color="secondary" startIcon={<ArrowLeftIcon />}>
            Prev
          </Button>
          <Button onClick={onClickNext} color="secondary" endIcon={<ArrowRightIcon />}>
            Next
          </Button>
        </Box>
      </Box>
      <Box p={{ xs: 2, md: 8 }} sx={{ pt: "8px!important" }} minHeight="80%" role="presentation">
        {children}
      </Box>
    </Drawer>
  );
};
export default SlideDrawer;
