import React from "react";
import Typography from "@mui/material/Typography";

interface Props {
  children: string;
  required?: boolean;
}

export const InputLabel = (props: Props) => {
  const { children, required = false } = props;
  return (
    <Typography
      sx={{ display: "inline-flex", fontSize: "13px" }}
      component="label"
      color="textSecondary"
      variant="body2"
    >
      {children} {required && <span style={{ color: "red" }}>*</span>}
    </Typography>
  );
};
