import React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MobileOffIcon from "@mui/icons-material/MobileOff";

export const dialogInitState = {
  open: false,
  content: "",
  title: "",
  okButtonLabel: "",
  handleOk: null,
  cancelButtonLabel: "",
  handleCancel: null,
  icon: null,
};

export const dialogReducer = (state, action) => {
  switch (action.type) {
    case "ACCOUNT_SWITCH":
      return {
        open: true,
        content: " You just switched your account! please refresh the page.",
        title: "Account changed!",
        okButtonLabel: "refresh",
        handleOk: () => window.location.reload(),
        // eslint-disable-next-line
        icon: <AccountBalanceWalletIcon style={{ fontSize: 100 }} />,
      };

    case "WALLET_MISSING":
      return {
        open: true,
        content: "Please install Keplr wallet extension",
        title: "Keplr wallet extension missing !",
        okButtonLabel: "Get extension",
        handleOk: () =>
          window.location.replace(
            "https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en"
          ),
        cancelButtonLabel: "Close",
        handleCancel: action.payload.clearDialogProps,
        icon: <AccountBalanceWalletIcon style={{ fontSize: 100 }} />,
      };

    case "IS_MOBILE":
      return {
        open: true,
        content:
          "ActiList doesn't support mobile devices currently, Please use desktop version at the moment.",
        title: "Mobile support ",
        okButtonLabel: "Ok",
        handleOk: action.payload.clearDialogProps,
        cancelButtonLabel: null,
        handleCancel: null,
        icon: <MobileOffIcon style={{ fontSize: 60 }} />,
      };
    case "CLEAR":
      return null;
    default:
      return null;
  }
};
