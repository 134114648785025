export const lightTheme = {
  mode: "light",
  primary: {
    main: "#f98137",
    light: "#ff8745",
    dark: "#f15702",
    contrastText: "#fff",
  },
  secondary: {
    main: "#100c08",
  },

  background: {
    paper: "#fff",
    default: "#fefefe",
  },
  text: {
    primary: "#222",
  },
};

export const darkTheme = {
  mode: "dark",
  primary: {
    main: "#f98137",
    light: "#ff8745",
    dark: "#f15702",
  },
  secondary: {
    main: "#efefef",
  },

  background: {
    paper: "#0F1729",
    default: "#0C1322",
  },
  text: {
    primary: "#FAF9F6",
  },
};
