import {
  ContractDefinition,
  ContractMessageRequest,
  createContractClient,
  snip20Def,
  extendContract,
  Snip20Contract,
  Context,
  ContractQueryRequest,
} from "@stakeordie/griptape.js";

const myDef: ContractDefinition = {
  queries: {
    getBalance({ address, key }: Context): ContractQueryRequest {
      return { balance: { address, key } };
    },
  },
  messages: {
    send(_ctx, recipient, amount): ContractMessageRequest {
      const handleMsg = {
        send: {
          recipient,
          amount,
        },
      };
      return { handleMsg, fees: 450000 };
    },
    setViewingKey({ padding }: Context, key: string) {
      const handleMsg = {
        set_viewing_key: { key, padding },
      };
      return { handleMsg };
    },
  },
};

const extendedSnip20Def = extendContract(snip20Def, myDef);

export const BidContract = (address: string) => {
  const contract = createContractClient<Snip20Contract>({
    id: address,
    at: address,
    definition: extendedSnip20Def,
  });
  return contract as Snip20Contract;
};
