import { createSlice } from "@reduxjs/toolkit";
import { AuctionModel } from "app/models/auction";
import { LOADING } from "app/utils/constants/others";
import { toast } from "react-toastify";
import { initialState } from "./state";

import {
  getAuctionInfo,
  isAuctionOwner,
  isAuctionHasBids,
  getBalance,
  placeBid,
  changeMinimumBid,
  retractBid,
  getPlacedBid,
  verifyAuction,
  saveFailedAuction,
  finalizeAuction,
} from "./reducers";

const auctionsInfoSlice = createSlice({
  name: "auctionsInfoSlice",
  initialState,
  reducers: {
    resetAuctionDetails: (state) => {
      state.auction = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAuctionInfo.pending, (state) => {
      state.loading.getAuctionInfo = LOADING.PENDING;
    });

    builder.addCase(getAuctionInfo.fulfilled, (state, action) => {
      const auction = new AuctionModel(action.payload);
      state.auction = auction;
      state.loading.getAuctionInfo = LOADING.SUCCEEDED;
    });

    builder.addCase(getAuctionInfo.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.getAuctionInfo = LOADING.FAILED;
    });

    builder.addCase(getPlacedBid.fulfilled, (state, action) => {
      if (action.payload.status === "Success") {
        state.placedBid = action.payload;
      }
    });

    builder.addCase(isAuctionOwner.fulfilled, (state, action) => {
      state.isOwner = action.payload;
    });
    builder.addCase(isAuctionOwner.rejected, (state, action) => {
      state.errors = action.error;
    });

    builder.addCase(isAuctionHasBids.fulfilled, (state, action) => {
      if (action.payload) {
        state.hasBids = !!action.payload;
      }
    });
    builder.addCase(isAuctionHasBids.rejected, (state, action) => {
      state.errors = action.error;
    });

    builder.addCase(placeBid.pending, (state) => {
      state.loading.placeBid = "place-bid";
    });
    builder.addCase(placeBid.fulfilled, (state) => {
      state.loading.placeBid = "";
      state.loading.placeBid = "get-placed-bid";
      toast.success("Bid successfully placed.");
    });
    builder.addCase(placeBid.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.placeBid = "";
      toast.error("Please check your sSCRT balance and try again!");
    });

    builder.addCase(changeMinimumBid.pending, (state) => {
      state.loading.changeMinimumBid = "change-min-bid";
    });
    builder.addCase(changeMinimumBid.fulfilled, (state) => {
      state.loading.changeMinimumBid = "";
      toast.success("Bid successfully changed.");
    });
    builder.addCase(changeMinimumBid.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.changeMinimumBid = "";
      toast.error(action.error.message);
    });

    builder.addCase(finalizeAuction.pending, (state) => {
      state.loading.finalizeAuction = "finalize";
    });
    builder.addCase(finalizeAuction.fulfilled, (state) => {
      state.loading.finalizeAuction = "";
      toast.success("Auction successfully finalized.");
    });
    builder.addCase(finalizeAuction.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.finalizeAuction = "";
      toast.error(action.error.message);
    });

    builder.addCase(retractBid.pending, (state) => {
      state.loading.retractBid = LOADING.PENDING;
    });
    builder.addCase(retractBid.fulfilled, (state) => {
      state.loading.retractBid = LOADING.SUCCEEDED;
      state.placedBid = null;
    });
    builder.addCase(retractBid.rejected, (state, action) => {
      state.errors = action.payload;
      state.loading.retractBid = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(verifyAuction.pending, (state) => {
      state.loading.verifyAuction = LOADING.PENDING;
    });
    builder.addCase(verifyAuction.fulfilled, (state) => {
      state.actionsLoading = "success-verification";
      toast.success("Auction successfully verified.");
      state.loading.verifyAuction = LOADING.SUCCEEDED;
    });
    builder.addCase(verifyAuction.rejected, (state, action) => {
      state.errors = action.error;
      state.loading.verifyAuction = LOADING.FAILED;
      toast.error(action.error.message);
    });

    builder.addCase(saveFailedAuction.rejected, (state, action) => {
      state.errors = action.error;
      toast.error(action.error.message);
    });
  },
});

export {
  getAuctionInfo,
  isAuctionOwner,
  isAuctionHasBids,
  placeBid,
  changeMinimumBid,
  retractBid,
  getPlacedBid,
  verifyAuction,
  saveFailedAuction,
  getBalance,
  finalizeAuction,
};

export const { resetAuctionDetails } = auctionsInfoSlice.actions;
export default auctionsInfoSlice.reducer;
