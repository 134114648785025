import * as React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Outlet } from "react-router-dom";
import { SidebarFilters } from "modules/marketplace/components/SidebarFilters";
import { PermanentDrawer } from "./components/Drawers";

const drawerWidth = 240;

export const MarketplaceLayout = () => {
  const [openFilters, setOpenFilters] = React.useState<boolean>(false);
  const handleOpenFilters = () => {
    setOpenFilters(true);
  };

  return (
    <Container maxWidth="xl" sx={{ display: "flex", pt: 15 }}>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        <PermanentDrawer>
          <SidebarFilters />
        </PermanentDrawer>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          p: { xs: 0, md: 3 },
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Outlet />

        <Hidden mdUp>
          <Fab
            onClick={handleOpenFilters}
            color="primary"
            sx={{
              position: "fixed",
              bottom: "16px",
              right: "16px",
            }}
          >
            <FilterListIcon sx={{ color: "white" }} />
          </Fab>
          <Drawer anchor="bottom" open={openFilters} onClose={() => setOpenFilters(false)}>
            <Stack sx={{ p: 3 }}>
              <SidebarFilters />
              <Button onClick={() => setOpenFilters(false)}>close</Button>
            </Stack>
          </Drawer>
        </Hidden>
      </Box>
    </Container>
  );
};
