import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  endsAt: number;
}
const Timer = (props: Props) => {
  const { endsAt } = props;
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const setNewTime = () => {
    if (endsAt) {
      const currentTime = new Date().getTime();

      const distanceToDate = endsAt * 1000 - currentTime;

      const days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distanceToDate % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      setState({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  useEffect(() => {
    const int = setInterval(() => setNewTime(), 1000);
    return () => clearInterval(int);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatter = (time) => {
    const t = Number(time);
    if (t <= 0) {
      return "00";
    }
    if (t > 9) {
      return `${t}`;
    }
    return `0${t}`;
  };

  return (
    <Box
      sx={{
        p: "2px",
        display: "inline-flex",
        borderRadius: "4px",
        background: "linear-gradient(to right, #f83600 0%, #f9d423 100%)",
      }}
    >
      <Box
        sx={{
          minWidth: "130px",
          borderRadius: "4px",
          display: "inline-flex",
          justifyContent: "center",
          background: (theme) => theme.palette.background.paper,
          py: "1px",
          "& .MuiTypography-root": {
            fontWeight: "600",
            fontSize: "11px",
          },
        }}
      >
        {state.days > 0 && <Typography sx={{ mr: 1 }}>{state.days} days </Typography>}
        <Typography>{formatter(state.hours)}:</Typography>
        <Typography>{formatter(state.minutes)}:</Typography>
        <Typography>{formatter(state.seconds)}</Typography>
      </Box>
    </Box>
  );
};

export default Timer;
