import * as React from "react";
import { Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { finalizeAuction } from "infrastructure/store/slices/auction-details";
import { listMyAuctions } from "infrastructure/store/slices/dashboard";
import { listLiveAuctions } from "infrastructure/store/slices/marketplace";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { TextSmallSecondary } from "components/Typography";

interface Props {
  isOwner: boolean;
  isEnded: boolean;
  auctionAddress: string;
}

export const FinalizeAuction = (props: Props) => {
  const { isOwner, isEnded, auctionAddress } = props;
  const navigate = useNavigate();
  const state = useAppSelector((s) => s.auctionDetails);

  const dispatch = useAppDispatch();

  const handleFinalize = async (): Promise<void> => {
    const fees = 2500000;
    await dispatch(finalizeAuction({ auction_address: auctionAddress, fees }));

    navigate("/marketplace");
    dispatch(listLiveAuctions());
    dispatch(listMyAuctions());
  };

  return (
    <>
      <Box my={2}>
        <Typography gutterBottom variant="subtitle2">
          Close Auction
        </Typography>
        <TextSmallSecondary>
          When due date is reached everyone can close the auction. Auction creator can close the
          auction at any time. As long the auction hasn&apos;t been closed, bids will still be
          accepted.
        </TextSmallSecondary>
      </Box>
      <LoadingButton
        fullWidth
        loading={state.actionsLoading === "finalize"}
        onClick={handleFinalize}
        color="secondary"
        variant="outlined"
        disabled={isOwner ? false : !isEnded}
      >
        Finalize
      </LoadingButton>
    </>
  );
};
