// import { Profile, ProfileMedia, Auth } from "app/types"
import { ProfileMedia } from "app/types";
import { LOADING } from "app/utils/constants/others";

interface Loadings {
  getProfile: string;
  updateProfile: string;
  uploadMedia: string;
  getEvaluation: string;
  getAssets: string;
  addCollection: string;
  createCollectionVk: string;
  importCollectionNfts: string;
  whitelisting: string;
  mint: string;
  initAssets: string;
}

interface ProfileInfo {
  username: string;
  wallet_address: string;
  telegram_handle: string;
  twitter_handle: string;
  notification_active: boolean;
}

interface User {
  userId: string;
  profileInfo: ProfileInfo;
  profileMedia: ProfileMedia;
  userEvaluation: any;
  userAssets: any;
  isWhitelisted: boolean | undefined;
  loading: Loadings;
  error: any;
}

export const profileInfoInitState: ProfileInfo = {
  username: "",
  wallet_address: "",
  telegram_handle: "",
  twitter_handle: "",
  notification_active: false,
};

const profileMediaInitState: ProfileMedia = {
  profile_photo: "",
  cover_photo: "",
};

const initialLoadingState: Loadings = {
  getProfile: LOADING.IDLE,
  updateProfile: LOADING.IDLE,
  uploadMedia: LOADING.IDLE,
  getEvaluation: LOADING.IDLE,
  getAssets: LOADING.IDLE,
  addCollection: LOADING.IDLE,
  createCollectionVk: LOADING.IDLE,
  importCollectionNfts: LOADING.IDLE,
  whitelisting: LOADING.IDLE,
  mint: LOADING.IDLE,
  initAssets: LOADING.IDLE,
};

export const initialState: User = {
  userId: "",
  profileInfo: profileInfoInitState,
  profileMedia: profileMediaInitState,
  userEvaluation: undefined,
  userAssets: {},
  isWhitelisted: undefined,
  loading: initialLoadingState,
  error: null,
};
