import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { login } from "infrastructure/store/slices/authentication";
import { SpinnerLoader } from "components/Spinner";
import { AlertScreen } from "components/AlertScreen";
import { LOADING } from "app/utils/constants/others";
// import { getUserEvaluation } from "infrastructure/store/slices/user";
import { ProfileHeader } from "./components/ProfileHeader";
import { ProfileSidebar } from "./components/ProfileSidebar";
import { PermanentDrawer } from "./components/Drawers";

const drawerWidth = 240;

export const ProfileLayout = React.memo(() => {
  const { loading, isAuthenticated } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const authenticate = React.useCallback(() => {
    dispatch(login());
  }, [dispatch]);

  /* React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserEvaluation());
    }
  }, [isAuthenticated, dispatch]); */

  if (loading.login === LOADING.PENDING || loading.currentAuthenticated === LOADING.PENDING) {
    return <SpinnerLoader />;
  }

  if (!isAuthenticated) {
    return (
      <AlertScreen
        height="60vh"
        title="Unauthenticated !"
        description="Please login first to be able to access your profile."
        okLabel="Authenticate"
        onOk={authenticate}
      />
    );
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 15 }}>
      <ProfileHeader />

      <Box sx={{ display: "flex" }}>
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
          <PermanentDrawer>
            <ProfileSidebar />
          </PermanentDrawer>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            p: 3,
            pr: 0,
            width: { md: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
});
