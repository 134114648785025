import { Auctions } from "infrastructure/services/blockchain/auctions";
import { Collections } from "infrastructure/services/blockchain/collections";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { NftContract } from "infrastructure/Contract/snip721";
import { viewingKeyManager } from "@stakeordie/griptape.js";
import { addressValidator } from "app/utils/_helpers";
import { Auction } from "app/types";

interface CollectionAddress {
  collection_address: string;
}

interface AccessProps {
  collection_address: string;
  tokenId: string;
}

export const createAuction = createAsyncThunk<unknown, { auction: Auction; fees: number }>(
  "create-auction",
  async ({ auction, fees }) => {
    const res = await Auctions.createAuction(auction, fees);
    return res;
  }
);

export const approveNftAccess = createAsyncThunk<string, AccessProps>(
  "approve-access",
  async ({ collection_address, tokenId }) => {
    await Collections.approveNftAccess(collection_address, tokenId)
      .then(() => true)
      .catch(() => false);
    return tokenId;
  }
);

export const revokeNftAccess = createAsyncThunk<string, AccessProps>(
  "revoke-access",
  async ({ collection_address, tokenId }) => {
    await Collections.revokeNftAccess(collection_address, tokenId)
      .then(() => true)
      .catch(() => false);
    return tokenId;
  }
);

export const createCollectionVk = createAsyncThunk<string, CollectionAddress>(
  "create-col-vk",
  async ({ collection_address }) => {
    if (!addressValidator(collection_address)) {
      throw new Error("invalid address given!");
    }

    const contract = NftContract(collection_address);
    const existingKey = viewingKeyManager.get(collection_address);
    if (existingKey) {
      return existingKey;
    }

    const res = await Collections.createCollectionVk(collection_address);
    if (res.isEmpty()) return null;
    const {
      viewing_key: { key },
    } = res.parse();
    // eslint-disable-next-line
    // @ts-ignore
    viewingKeyManager.add(contract, key);
    return key;
  }
);

export const getCollectionCodeHash = createAsyncThunk<string, CollectionAddress>(
  "get-code-hash",
  async ({ collection_address }) => {
    const res = await Collections.getCollectionCodeHash(collection_address);
    return res;
  }
);

export const listCollectionTokens = async (collection_address: string) => {
  const collectionVk = viewingKeyManager.get(collection_address);
  const {
    token_list: { tokens },
  } = await Collections.listCollectionTokens(collection_address, collectionVk);
  return tokens;
};

export const getCollectionNftsDossier = async (
  collection_address: string,
  tokensIds: Array<string>
) => {
  const promises = tokensIds.map((tokenId: string) =>
    Collections.getNftDossier(collection_address, tokenId)
  );
  const result = await Promise.all(promises);

  return result;
};

interface CollectionNfts {
  collection_address: string;
  collection_nfts: Array<any>;
  notify?: boolean;
}

export const importCollectionNfts = createAsyncThunk<
  CollectionNfts,
  { collection_address: string; notify?: boolean }
>("create-auc-import", async ({ collection_address, notify = true }) => {
  const tokensIds = await listCollectionTokens(collection_address);
  const collectionNftsDossier = await getCollectionNftsDossier(collection_address, tokensIds);
  const collectionNfts = collectionNftsDossier.map(({ nft_dossier }, i) => {
    // eslint-disable-next-line
    const { public_metadata, token_approvals } = nft_dossier;
    const { extension } = public_metadata;
    return {
      id: tokensIds[i],
      name: extension?.name ?? "",
      description: extension?.description ?? "",
      image: extension?.media[0].url,
      approvals: token_approvals,
      collectionCreator: nft_dossier?.mint_run_info?.collection_creator,
      owner: nft_dossier?.owner,
    };
  });
  return {
    collection_address,
    collection_nfts: collectionNfts,
    notify,
  } as CollectionNfts;
});
