import React from "react";
import { Avatar } from "@mui/material";

interface Props {
  size?: string;
  name?: string;
  newPhoto?: any;
  profile_photo?: any;
}

const UserAvatar = (props: Props) => {
  const { size = "medium", newPhoto } = props;

  return (
    <Avatar
      sx={{
        width: size === "small" ? "40px" : size === "large" ? "150px" : "80px",
        height: size === "small" ? "40px" : size === "large" ? "150px" : "80px",
        background: "linear-gradient(158deg, rgba(85,112,171,1) 0%, rgba(43,56,86,1) 100%)",
      }}
      src={newPhoto || props?.profile_photo}
    >
      {!props?.profile_photo && props?.name?.toUpperCase().charAt(0)}
    </Avatar>
  );
};

export default UserAvatar;
