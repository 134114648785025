import { API } from "app/libs/api";

export class AuctionsService {
  /**
   * @temporary until smart contract fixed
   *
   * @returns {failed auctions} with negative verification.
   */

  static listFailedAuctions() {
    const path = "auctions?pagination[limit]=-1";
    return API.get(path);
  }

  static saveFailedAuction(auction_address: string) {
    const data = { data: { title: auction_address } };
    return API.post("auctions", data);
  }
}
