import * as React from "react";
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { login } from "infrastructure/store/slices/authentication";
import { SpinnerLoader } from "components/Spinner";
import { AlertScreen } from "components/AlertScreen";
import { Container } from "@mui/material";
import { LOADING } from "app/utils/constants/others";
import { DashboardSidebar } from "./components/DashboardSidebar";
import { PermanentDrawer } from "./components/Drawers";

const drawerWidth = 240;
const mainStyle = {
  flexGrow: 1,
  p: 3,
  width: { md: `calc(100% - ${drawerWidth}px)` },
};

interface MainProps {
  loading: any;
  isAuthenticated: boolean;
}

const Main = (props: MainProps) => {
  const { loading, isAuthenticated } = props;
  const dispatch = useAppDispatch();

  const authenticate = () => {
    dispatch(login());
  };

  if (loading.login === LOADING.PENDING || loading.currentAuthenticated === LOADING.PENDING) {
    return <SpinnerLoader />;
  }

  if (!isAuthenticated) {
    return (
      <AlertScreen
        title="Unauthenticated !"
        description="Please login first to be able to access your dashboard."
        okLabel="Authenticate"
        onOk={authenticate}
      />
    );
  }

  return <Outlet />;
};

export const DashboardLayout = () => {
  const { loading, isAuthenticated } = useAppSelector((state) => state.auth);
  return (
    <Container maxWidth="xl" sx={{ display: "flex", pt: 15 }}>
      <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
        <PermanentDrawer>
          <DashboardSidebar />
        </PermanentDrawer>
      </Box>
      <Box component="main" sx={mainStyle}>
        <Main loading={loading} isAuthenticated={isAuthenticated} />
      </Box>
    </Container>
  );
};
