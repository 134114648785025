import * as React from "react";
import { ClosedAuctionDetails } from "modules/marketplace/closed-auctions/details";
import { Helmet } from "react-helmet";

const ClosedAuctionsDetailsSlider = () => (
  <>
    <Helmet>
      <title>Marketplace | Details</title>
    </Helmet>
    <ClosedAuctionDetails />
  </>
);

export default ClosedAuctionsDetailsSlider;
