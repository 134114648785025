import { API } from "app/libs/api";
import { Profile } from "app/types";
import { getAddress } from "@stakeordie/griptape.js";

export class ProfileService {
  static getProfile() {
    const walletAddress = getAddress();

    return API.get(`/profiles/${walletAddress}`);
  }

  static addProfile(profile) {
    const data = { data: { ...profile } };
    return API.post("/profiles", data);
  }

  static updateProfile(profile: Profile, id: string | number) {
    const data = { data: { ...profile } };

    return API.put(`/profiles/${id}`, data);
  }

  static formatMediaData(img: File, profileId, field: string) {
    const data = new FormData();
    data.append("files", img);
    data.append("ref", "api::profile.profile");
    data.append("refId", profileId);
    data.append("field", field);

    return data;
  }

  static uploadProfileMedia(img: File, profileId: string | number, field: string) {
    const data = this.formatMediaData(img, profileId, field);

    if (!data) throw new Error("no media data !");

    return API.post(`/upload`, data);
  }

  static authenticate(vk: string) {
    const walletAddress = getAddress();
    const data = { username: walletAddress, vk };
    return API.post("/auth/authenticate", data);
  }

  static precalculateUserEvaluation() {
    const walletAddress = getAddress();
    // const walletAddress = "secret1usjcttfpp7avjrex3gt82fkt32d02d5rra83cg";
    return API.post(`/karmas/${walletAddress}/precalculate`, {});
  }

  static calculateUserEvaluation() {
    const walletAddress = getAddress();
    // const walletAddress = "secret1usjcttfpp7avjrex3gt82fkt32d02d5rra83cg";
    return API.post(`/karmas/${walletAddress}/calculate`, {});
  }

  static getUserEvaluation() {
    const walletAddress = getAddress();
    // const walletAddress = "secret1usjcttfpp7avjrex3gt82fkt32d02d5rra83cg";
    return API.get(`/karmas/${walletAddress}`);
  }

  /**
   *
   * @remarks
   *
   */

  static listUserCollections() {
    const walletAddress = getAddress();
    return API.get(`/collection/${walletAddress}`);
  }

  static addUserCollection(userAssets, newCollection) {
    const walletAddress = getAddress();
    const userRacordId = userAssets?.id;
    const existingCollections = userAssets?.collections?.collections;

    const data = {
      data: {
        owner: walletAddress,
        collections: {
          ...userAssets?.collections,
          collections: existingCollections
            ? [...existingCollections, newCollection]
            : [newCollection],
        },
      },
    };
    return API.put(`/collections/${userRacordId}`, data);
  }

  static incrementMintAmount(userAssets) {
    const walletAddress = getAddress();
    const currentAmount = userAssets?.collections?.minted;
    const data = {
      data: {
        owner: walletAddress,
        collections: {
          ...userAssets?.collections,
          minted: currentAmount ? 2 : 1,
        },
      },
    };
    return API.put(`/collections/${userAssets?.id}`, data);
  }

  static initUserAssets() {
    const walletAddress = getAddress();
    const data = {
      data: {
        owner: walletAddress,
        collections: {
          minted: 0,
          collections: [],
        },
      },
    };

    return API.post("/collections", data);
  }
}
