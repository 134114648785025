import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import UserAvatar from "components/UserAvatar";
import { FileUploader } from "react-drag-drop-files";
import EditIcon from "@mui/icons-material/Edit";
import CopiableAddress from "components/CopiableAddress";
import { useAppSelector, useAppDispatch } from "infrastructure/store/hooks";
import { uploadMedia } from "infrastructure/store/slices/user";
import { TextSmallPrimary } from "components/Typography";
import { profileAvatarContainer, profileAvatar, overlayProfile } from "./styles";

const fileTypes = ["JPG", "PNG", "JPEG"];

interface Props {
  newImages: any;
  setNewImages: (p: any) => void;
}

export const ProfilePhoto = (props: Props) => {
  const { newImages, setNewImages } = props;
  const [profileHovered, setProfileHovered] = React.useState(false);
  const {
    userId,
    profileInfo: { username },
    profileMedia,
  } = useAppSelector((state) => state.user);
  const { walletName, walletAddress } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleProfilePhotoChange = (img) => {
    setNewImages({
      ...newImages,
      profile_photo: { file: img, link: window.URL.createObjectURL(img) },
    });
  };

  const clearProfilePhoto = () => {
    setNewImages({
      ...newImages,
      profile_photo: { link: null, file: null },
    });
  };

  const saveImage = async () => {
    const img = newImages.profile_photo.file;
    const profileId = userId;
    const field = "profile_photo";

    dispatch(uploadMedia({ img, profileId, field }));
    clearProfilePhoto();
  };

  return (
    <Box sx={profileAvatarContainer}>
      <Box
        onMouseEnter={() => setProfileHovered(true)}
        onMouseLeave={() => setProfileHovered(false)}
      >
        <Box sx={profileAvatar}>
          {/* eslint-disable-next-line */}
          {profileHovered && !newImages.profile_photo.link ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              zIndex={14}
            >
              <Button startIcon={<EditIcon fontSize="small" />}>Edit</Button>
            </Box>
          ) : newImages.profile_photo.link ? (
            <Box
              sx={{
                ...overlayProfile,
                gap: 2,
                pt: 1,
                "& .MuiBox-root": { cursor: "pointer" },
              }}
              zIndex={16}
            >
              <Box onClick={clearProfilePhoto}>
                <TextSmallPrimary style={{ color: "red" }}>remove</TextSmallPrimary>
              </Box>
              <Box onClick={saveImage}>
                <TextSmallPrimary>save</TextSmallPrimary>
              </Box>
            </Box>
          ) : null}
          <UserAvatar
            size="large"
            newPhoto={newImages.profile_photo.link}
            profile_photo={profileMedia?.profile_photo}
          />
          <FileUploader
            name="profile_photo"
            classes="imageuploader1"
            types={fileTypes}
            handleChange={handleProfilePhotoChange}
          />
        </Box>
      </Box>
      <Box sx={{ zIndex: 15, mb: 2 }}>
        <Typography gutterBottom variant="h6">
          {username !== "" ? username : walletName}
        </Typography>
        <CopiableAddress text={walletAddress} />
      </Box>
    </Box>
  );
};
