import React, { useState, createContext, useReducer, useEffect, useMemo, useCallback } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { onAccountChange, onAccountNotAvailable } from "@stakeordie/griptape.js";
import Dialog from "components/Dialog";
import getTheme from "theme";
import axios from "axios";
import { LOADING } from "app/utils/constants/others";
import { getItemFromLS, saveItemToLS } from "app/utils/_helpers/localstorage";
import { initialAuthLoad } from "infrastructure/store/slices/authentication";
import { useAppDispatch } from "infrastructure/store/hooks";
import { dialogReducer, dialogInitState } from "./reducers";

const TOKEN_INFO_API = "https://core.spartanapi.dev/secret/chains/secret-4/market_info/?";

const SettingsContext = createContext(null);

interface Props {
  children: any;
}

export const SettingsProvider = (props: Props) => {
  const { children } = props;
  const [dialogProps, dispatch] = useReducer(dialogReducer, dialogInitState);

  const [view, setView] = useState("list");
  const [width, setWidth] = useState(window?.innerWidth);
  const [themeMode, setThemeMode] = useState("light");
  const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [scrtTokenInfo, setScrtTokenInfo] = useState(null);
  const appDispatch = useAppDispatch();
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);

  const getScrtTokenInfo = async () => {
    await axios
      .get(TOKEN_INFO_API)
      .then((res) => setScrtTokenInfo(res.data))
      .catch((err) => console.error(err));
  };

  const switchThemeMode = useCallback(() => {
    setThemeMode((prev) => (prev === "light" ? "dark" : "light"));
  }, []);

  const clearDialogProps = () => {
    dispatch({ type: "CLEAR" });
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const detectThemeMode = () => {
    const currentThemeMode = getItemFromLS("acti-theme");
    if (currentThemeMode) {
      setThemeMode(currentThemeMode);
    } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setThemeMode("dark");
    }
  };

  useEffect(() => {
    detectThemeMode();
  }, []);

  useEffect(() => {
    saveItemToLS("acti-theme", themeMode);
  }, [themeMode]);

  useEffect(() => {
    appDispatch(initialAuthLoad(LOADING.PENDING));
    const stopListen = onAccountNotAvailable(() => {
      appDispatch(initialAuthLoad(LOADING.IDLE));
    });

    return () => {
      stopListen();
    };
  }, []);

  useEffect(() => {
    const stopListen = onAccountChange(() => {
      dispatch({ type: "ACCOUNT_SWITCH", payload: { clearDialogProps } });
    });

    window.addEventListener("resize", handleWindowSizeChange);
    const isMobile = width <= 768;

    setTimeout(() => {
      if (!(window as any).keplr && !isMobile) {
        dispatch({
          type: "WALLET_MISSING",
          payload: { clearDialogProps },
        });
      }
    }, 6000);

    if (isMobile) {
      appDispatch(initialAuthLoad(LOADING.IDLE));
      dispatch({ type: "IS_MOBILE", payload: { clearDialogProps } });
    }

    getScrtTokenInfo();
    return () => {
      stopListen();
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const settings = {
    mobileFilterOpen,
    setMobileFilterOpen,
    view,
    setView,
    scrtTokenInfo,
    switchThemeMode,
  };

  return (
    // eslint-disable-next-line
    <SettingsContext.Provider value={{ ...settings }}>
      <ThemeProvider theme={theme}>
        {children}
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        {dialogProps && <Dialog {...dialogProps} />}
      </ThemeProvider>
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
